import React from 'react'
import { Spinner, Center } from '@chakra-ui/react';

function LoadingSpinner2() {
    return (
        <Center h="100vh"> {/* Center the spinner vertically and horizontally */}
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      );
}

export default LoadingSpinner2
