import React from 'react';
import styled from 'styled-components';
import WebFont from 'webfontloader';


WebFont.load({
  google: {
    families: ['Mukta']
  }
});


const lightBlue = '#8CCBFF'; // Light Blue
const pink = '#FFC0CB'; // Pink
const white = '#fff';
const shadow = 'rgba(0, 0, 0, 0.15)'; // Softer shadow

const Bubble = styled.div`
  position: relative;
  display: inline-block;
  margin: 20px;
  text-align: center;
  background: linear-gradient(145deg, ${white}, ${pink}); // Gradient background
  color: #4A4A4A; // Darker text color for better readability
  padding: calc(10 * 4px);
  box-shadow: 0 8px 15px ${shadow}; // Softer and more spread shadow
  border-radius: 40% 31% 56% 44% / 54% 44% 55% 46%;
  width: auto;
  height: auto;
  max-width: 300px;
  font-family: 'Mukta', sans-serif; 
  font-weight: bold; 
  font-size: 20px; // Slightly larger font size
  line-height: 1.5em;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    top: -20px; // Adjust if necessary to better fit the new shape
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-bottom-color: ${white}; // Pointer matches the bubble color for continuity
    box-shadow: 0 2px 4px ${shadow};
    // Adjust the pointer to better match the new bubble shape
  }
`;

const SpeechBubble = ({ children }) => {
  return (
    <Bubble>
      {children}
    </Bubble>
  );
};

export default SpeechBubble;
