import React, { useState, useEffect, useRef } from 'react';
import { DndContext, useDraggable, useDroppable, DragOverlay } from '@dnd-kit/core';
import { Button, Box, VStack, HStack, Center, Text, useToast, Heading } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import AnswersOverlay from './AnswerOverlay';
import InstructionsOverlay from '../InstructionsOverlay/InstructionsOverlay';

//A draggable widget component
const DraggableWidget = ({ id, children, color, isCorrect, isIncorrect }) => {

  const { attributes, listeners, setNodeRef } = useDraggable({ id });
  const variants = {
    initial: { 
      scale: 1,
      
      backgroundColor: color
    },
    correct: {
      scale: 1.2,
      
      transition: { duration: 0.5, ease: "easeInOut" }
    },
    incorrect: {
      x: [-10, 10, -10, 10, -10, 10, 0], // shake horizontally
      
      transition: { duration: 0.82, ease: "easeInOut" }
    },
    exit: {
      scale: 1,
      opacity: 0, // fade out the emoji
      transition: { duration: 0.3, ease: "easeInOut" }
    },
    
  };

  // Emojis to show when correct
  const correctEmojis = "✨";
  
  return (
    <motion.div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      initial="initial"
      animate={isCorrect ? "correct" : isIncorrect ? "incorrect" : "initial"}
      exit="exit"
      variants={variants}
      style={{
        margin: '0.5',
        padding: '5',
        borderRadius: 'var(--chakra-radii-md)',
        cursor: 'grab',
        display: 'flex',
        position: 'relative',
        
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isCorrect ? correctEmojis : ""}
    <Box
      ref={setNodeRef}// Ref for DnD Kit to manage this DOM node
      {...listeners}//Event listeners for draggable functionality
      {...attributes}// Accessibility attributes for draggable
      m={2}
      p={2.5}
      bg={color}
      borderRadius="md"
      cursor="grab"// Cursor changes to a grab hand
    >
      {children}
    </Box>
    {isCorrect ? correctEmojis : ""}
    </motion.div>
  );
};

//A droppable zone component
const DroppableZone = ({ id, children, content, isCollapsed, toggleCollapsed, previewLength }) => {
  // useDroppable hook provides functionality to make the component droppable
    const { setNodeRef } = useDroppable({ id });
    const displayContent = isCollapsed ? `${content.slice(0, previewLength)}...` : content;
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };
  
      updateWidth();
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    }, []);
  
    return (
      <Box
      ref={node => {
        setNodeRef(node);
        containerRef.current = node;
      }}
        m={2}
        p={5}
        bg="yellow.600"
        minHeight="100px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
        width="auto"
        onClick={toggleCollapsed}
      >
 
        <Text fontSize="lg" fontWeight="bold" color="white" isTruncated={isCollapsed}>
          {displayContent}
        </Text>        

        <HStack spacing={2} align="center"  wrap="wrap"
        direction={containerWidth > 350 ? 'row' : 'column'}
        style={{ minWidth: '100%', justifyContent: 'center' }}>
        {children} {/*Displays any widgets dropped into this zone*/}
        </HStack>
      </Box>
    );
  };
// Main component to set up drag and drop functionality
const DragDrop = ({ activityData, onCompletion, name, description, instructions}) => {
  const toast = useToast();
  const [containers, setContainers] = useState(() => {
    const uniqueContainers = new Set();
    activityData.forEach(item => item.container.forEach(container => uniqueContainers.add(container)));
    return Array.from(uniqueContainers);
  });
 
  const finishActivity = () => {
    // Call the onCompletion function passed as a prop
    if(onCompletion){
      onCompletion();
    }
    
  };
  //console.log(`act data:` , activityData);
  if (!activityData || !Array.isArray(activityData)) {
    console.error('Activity data is not available or not in the expected format:', activityData);
    
  };

  const labelToZoneMap = activityData.reduce((acc, item) => {
    acc[item.draggable] = item.container;
    return acc;
  }, {});
  const [lastCorrectWidget, setLastCorrectWidget] = useState(null);
  const [lastIncorrectWidget, setLastIncorrectWidget] = useState(null);
  const [showAnswers, setShowAnswers] = useState(false);
  const [userAnswers, setUserAnswers] = useState({}); // This needs to be updated according to actual user actions.
  const [showInstructions, setShowInstructions] = useState(false);

  const contentPreviewLength = 30; 
  const widgetNames = Object.keys(labelToZoneMap);
  const dropzoneContents = Object.values(labelToZoneMap);

  const [activeId, setActiveId] = useState(null);
  const [zoneContents, setZoneContents] = useState(new Array(dropzoneContents.length).fill([]));
  const [widgetColors, setWidgetColors] = useState(new Array(widgetNames.length).fill("gray.300"));

  //console.log(typeof widgetNames)
  const [outsideZone, setOutsideZone] = useState(widgetNames.map((name, index) => `widget-${index}`));
  // Start with all collapsed
  const [collapsedStates, setCollapsedStates] = useState(() => {
    return containers.map(content => content.length >= 30);  // Collapse only if the content length is 30 or more characters
  });
  const toggleCollapse = (index) => {
    setCollapsedStates(collapsedStates.map((collapsed, i) => (i === index ? !collapsed : collapsed)));
  };
  // Prepare data for the answers overlay
  const answers = activityData.map(item => ({
    draggable: item.draggable,
    containers: item.container,
  }));

  const handleViewAnswers = () => setShowAnswers(true);
  const handleCloseAnswers = () => setShowAnswers(false);
  const toggleInstructions = () => setShowInstructions(!showInstructions);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    const activeIndex = parseInt(active.id.split('-')[1]);
    const newOutsideZone = [...outsideZone];
    const newWidgetColors = [...widgetColors];
    const newZoneContents = [...zoneContents];

    if (over) {
      const overIndex = parseInt(over.id.split('-')[1]);
      const previousIndex = newZoneContents.findIndex(contents => contents.includes(active.id));
      const draggable = widgetNames[activeIndex];
      const expectedZone = labelToZoneMap[draggable]; //lookup draggable in map to get expected zone
      const actualZone = containers[overIndex];
      const draggableName = widgetNames[activeIndex];
      const zoneName = containers[overIndex];
     
      setUserAnswers(prev => ({
        ...prev,
        [draggableName]: zoneName
      }));
      //console.log('userAnswers:', userAnswers)
      //console.log('answers:', answers)

      if (previousIndex !== -1) {
        newZoneContents[previousIndex] = newZoneContents[previousIndex].filter(id => id !== active.id);
      } else {
        newOutsideZone.splice(newOutsideZone.indexOf(active.id), 1);
      }

      newZoneContents[overIndex] = [...newZoneContents[overIndex], active.id];
      
      

      
      if (expectedZone.includes(actualZone)) {
        newWidgetColors[activeIndex] = "#328C62"; // Correct match - green
        setLastCorrectWidget(active.id); // Set the last correct widget
        setTimeout(() => setLastCorrectWidget(null), 1500);// Reset after 1.5 second
        if (expectedZone.length > 1) {
          toast({
            title: `${draggable} can be matched to: ${expectedZone.join(', ')}`,
            status: 'info',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        newWidgetColors[activeIndex] = "#E0392E"; // Incorrect match - red
        setLastIncorrectWidget(active.id);
        setTimeout(() => setLastIncorrectWidget(null), 1500);
      }

      setZoneContents(newZoneContents);
      setOutsideZone(newOutsideZone);
      setWidgetColors(newWidgetColors);
    } else {
      const previousIndex = newZoneContents.findIndex(contents => contents.includes(active.id));
      if (previousIndex !== -1) {
        newZoneContents[previousIndex] = newZoneContents[previousIndex].filter(id => id !== active.id);
        newOutsideZone.push(active.id);
        newWidgetColors[activeIndex] = "gray.300";
      }

      setZoneContents(newZoneContents);
      setOutsideZone(newOutsideZone);
      setWidgetColors(newWidgetColors);

    }
    //console.log(widgetColors)
  };
  // Ensure activityData is valid before rendering
  if (!activityData || !Array.isArray(activityData)) {
    return <p>Loading data or data format is incorrect...</p>;
  }

  // Render the DnD context and layout with widgets and zones
  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <VStack spacing={2} align="center" marginTop="3em">
        <Heading as="h1" size="xl" textAlign="center">{name}</Heading>
      <Button colorScheme="purple" onClick={toggleInstructions}>
          Activity Instructions🧭
        </Button>
      </VStack>
      <InstructionsOverlay isOpen={showInstructions} onClose={toggleInstructions} name={name} description={description} instructions={instructions} />

      <Center>
        <VStack spacing={8}>
          <HStack mt="3em" spacing="20em">
        <Button
            
            colorScheme="blue"
            onClick={finishActivity}
          >
            I'm done!👍
          </Button>
          <Button colorScheme="green" onClick={handleViewAnswers}>
          View Answers💡
        </Button>
        </HStack>
        <AnswersOverlay isOpen={showAnswers} onClose={handleCloseAnswers} answers={answers} userAnswers={userAnswers} />
        
          <HStack wrap="wrap" justify="center">
            {widgetNames.map((name, index) => {
              const id = `widget-${index}`;
              
              if (zoneContents.flat().includes(id)) {
                return null;
              }
              return (
                <DraggableWidget key={index} id={id}color={widgetColors[index]} >{name}</DraggableWidget>
              );
            })}
          </HStack>
          <HStack wrap="wrap" justify="center" mt="50px">
            {containers.map((content, index) => (
              <DroppableZone key={index}
              id={`zone-${index}`}
              content={content}
              isCollapsed={collapsedStates[index]}
              toggleCollapsed={() => toggleCollapse(index)}
              previewLength={contentPreviewLength}>
              {/* Render widgets that are in this zone */}
              {zoneContents[index].map(id => (
                  <DraggableWidget 
                  key={id} id={id} 
                  color={widgetColors[parseInt(id.split('-')[1])]} 
                  isCorrect={lastCorrectWidget === id}
                  isIncorrect={lastIncorrectWidget === id}>
                    {widgetNames[parseInt(id.split('-')[1])]}
                  </DraggableWidget>
                ))}
            </DroppableZone>
            ))}
          </HStack>
        </VStack>
      </Center>
      <DragOverlay>
        {activeId ? (
          <DraggableWidget id={activeId}>
            {widgetNames[parseInt(activeId.split('-')[1])]}
          </DraggableWidget>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default DragDrop;