import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import SlimetteAudienceImage from '../../Assets/Images/Slimette/Slimette_Audience.png';
import SlimetteAudienceEntertainedImage from '../../Assets/Images/Slimette/Slimette_Audience_Entertained.png';
import TextBubble2 from '../TextBubble/TextBubble2';

const MessengerSlimette = ({ isVisible, message, slimetteType }) => {
  if (!isVisible) return null;

  const getImage = () => {
    switch (slimetteType) {
      case 'entertained':
        return SlimetteAudienceEntertainedImage;
      case 'validator':
      default:
        return SlimetteAudienceImage;
    }
  };

  return (
    <Box textAlign="center" p={5} rounded="md" position="fixed" top="20px" right="20px" zIndex="tooltip">
      <Image src={getImage()} alt="Slimette Audience" width="200px" height="auto" m="auto" />
      <TextBubble2>{message}</TextBubble2>
    </Box>
  );
};

export default MessengerSlimette;
