import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Input, Button, VStack, Text, HStack, Heading, Stack, Center, Spinner, Checkbox } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faVideo, faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import InstructionsOverlay from '../InstructionsOverlay/InstructionsOverlay';
import ConfirmationModal from './ConfirmationModal';

function Roleplay({ activityData, onCompletion, name, description, instructions }) {
  const serverUrl = process.env.REACT_APP_BACKEND_URL; 
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSendDisabled, setIsSendDisabled] = useState(false); // State to manage send button
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // State to manage the confirmation modal
  const [isCheckBoxVisible, setIsCheckBoxVisible] = useState(true); // State to manage the checkbox visibility
  const [currentScenario, setCurrentScenario] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [options, setOptions] = useState([]);
  const [beginConversation, setBeginConversation] = useState(false);
  const [isFreeResponse, setIsFreeResponse] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [conversationCompleted, setConversationCompleted] = useState(false); // Flag to indicate if the conversation is completed
  const [currentDialogueIndex, setCurrentDialogueIndex] = useState(0);
  const [conversation, setConversation] = useState(null); // Current conversation
  const [conversationIndex, setConversationIndex] = useState(0); // Current conversation index
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [showAvatar, setShowAvatar] = useState(false);
  const chatTimeout = 800;
  const optionTimeout = 1200;
  const [showInstructions, setShowInstructions] = useState(false);
  const token = localStorage.getItem('token');
  const [userName, setUserName] = useState(localStorage.getItem('userName') || ''); // Default to local storage or empty

  useEffect(() => {
    if (currentScenario && conversationIndex + 1 === currentScenario.conversation.length) {
      setIsSendDisabled(true); // Disable the send button if the end of the conversation is reached
    }
  }, [conversationIndex, currentScenario]);

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${serverUrl}/user/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        localStorage.setItem('userName', data.name); // Store name in local storage
        setUserName(data.name); // Set name in state
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    if (!userName) { // Only fetch if username isn't already set
      fetchProfile();
    }
  }, [userName]);

  useEffect(() => {
    // Start activity when the component mounts
    const startActivity = async () => {
        try {
            setIsLoading(true);
            
            // Set the initial bot message and options
            const initialQuestion = activityData.userSelection[0].question;
            //console.log(`initialQuestion:`, initialQuestion);
            const initialOptions = activityData.userSelection[0].options;
            //console.log(`initialOptions:`, initialOptions);
            setTimeout(() => {
            setMessages([{ text: initialQuestion, sender: 'bot' }]);
            }, chatTimeout);
            setTimeout(() => {
            setOptions(initialOptions);
            }, optionTimeout);
            //console.log(`activityData:`, activityData);
            
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
      
    };
    startActivity();
  }, []);

  // Function to handle the input made by the user
  const handleInput = (option) => {
  
  
  // If the conversation hasn't started, process the selection for initial questions.
  if (!beginConversation) {
    
    setMessages((prevMessages) => [...prevMessages, { text: option, sender: 'user' }]);

    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < activityData.userSelection.length) {
      // Update user responses and set the next question
      setUserResponses((prev) => ({ ...prev, [activityData.userSelection[currentQuestionIndex].tag]: option }));
      setCurrentQuestionIndex(nextIndex);  // Update the question index first
      // Then set the messages to include the next question
      setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: activityData.userSelection[nextIndex].question, sender: 'bot' },
      ]);
      
    }, chatTimeout);
      setTimeout(() => {
      setOptions(activityData.userSelection[nextIndex].options);
    }, optionTimeout);
      
    } else if (nextIndex === activityData.userSelection.length) {
      // This was the last initial question; now ask if the user is ready to start the scenario
      setUserResponses((prev) => ({ ...prev, [activityData.userSelection[currentQuestionIndex].tag]: option }));
      setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: 'Are you ready to start the conversation?', sender: 'bot' },
      ]);
      
    }, chatTimeout);
      setTimeout(() => {
      setOptions(['I\'m ready!']);
    }, optionTimeout);
      
      // Set this flag to true to indicate the user has finished the initial questions
      setBeginConversation(true);
    }
  } else {
    // The user is ready to start the scenario conversation
    if (option === 'I\'m ready!') {
      // Clear the messages and set the first dialogue of the scenario
      setMessages([]); // Clear the messages for the scenario start
      setShowAvatar(true); // Show the avatar
      const scenario = activityData.scenarios.find((s) => s.scenario === userResponses.scenario);
      if (scenario && scenario.conversation.length > 0) {
        setCurrentScenario(scenario);
        //console.log(`scenario:`, scenario);
        setConversation(scenario.conversation[1]);
        setConversationIndex(1);

        let userNameLocal = userName || localStorage.getItem('userName'); // Get userName from state or local storage

        // Check if userNameLocal is null and adjust based on relation
        if (!userNameLocal) {
          switch (userResponses.relation) {
            case 'Parent':
              userNameLocal = 'kiddo';
              break;
            case 'Friend':
            case 'Healthcare practitioner':
              userNameLocal = 'my friend';
              break;
            default:
              userNameLocal = 'there'; // Fallback if no specific relation found
          }
        }
        console.log(`userNameLocal:`, userNameLocal);
        
        if (scenario.conversation[0].dialogues[0].dialogue && scenario.conversation[0].dialogues[0].dialogue.includes('@{name}')) {

          const updatedDialogue = scenario.conversation[0].dialogues[0].dialogue.replace('@{name}', userNameLocal);
          setTimeout(() => {
          setMessages([{ text: updatedDialogue, sender: 'bot' }]);
          
          }, chatTimeout);
          setTimeout(() => {
            setOptions(scenario.conversation[0].options || []);
          }, optionTimeout);
        }
      }
    } else {

      // Handle the user's response to the scenario dialogues
      setUserResponses((prev) => ({ ...prev, 'previousAnswer': option }));
      const previousAnswer = option; //set the previous answer

      
      //const previousAnswer = userResponses.previousAnswer;
      setMessages((prevMessages) => [...prevMessages, { text: option, sender: 'user' }]); // Add the user's response to the messages
      //console.log(`currentDialogueIndex:`, currentDialogueIndex);  
      //console.log(`conversation:`, conversation);
      let message;
      //console.log(conversation.dialogues[0].condition);
      if (conversation.dialogues[0].condition === null) {
        message = conversation.dialogues[0].dialogue;

      }else {
        // Find the dialogue that matches the condition based on the user's previous responses
        message = conversation.dialogues.find(dialogue => {
          switch (dialogue.condition.tag) {
            case 'scenario':
              return  dialogue.condition.shouldBe.includes(userResponses.scenario);
            case 'relation':
              return dialogue.condition.shouldBe.includes(userResponses.relation);
          
            case 'previousAnswer':
              return dialogue.condition.shouldBe.includes(previousAnswer);
          }}).dialogue;        
      }
      
      //console.log(`message:`, message);
      if (message) {
        setIsSendDisabled(true);
        setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, { text: message, sender: 'bot' }]); // Add the bot's response to the messages
        // Enable the send button only if the conversation is not completed
        if (!(conversationIndex + 1 === currentScenario.conversation.length)) {
          setIsSendDisabled(false); // Enable the send button after bot responds
      }}, chatTimeout);
        switch (conversation.responseType) {

          case 'selection':
            setIsFreeResponse(false);
            setIsMultiSelect(false);
            setTimeout(() => {
            setOptions(conversation.options||[]);
          }, optionTimeout);
            break;
          case 'text':
            setIsMultiSelect(false);
            setIsFreeResponse(true);
            break;
          case 'multiselect':
            setIsFreeResponse(false);
            setIsMultiSelect(true);
            setTimeout(() => {
            setOptions(conversation.options||[]);
          }, optionTimeout);
            break;

        }
        if (conversationIndex+1 === currentScenario.conversation.length) {
          setIsSendDisabled(true);
          setIsCheckBoxVisible(false);
          setTimeout(() => {
            setMessages((prevMessages) => [...prevMessages, { text: 'Well done! You have reached the end of the roleplay dialogue. A pop-up window will show up shortly.', sender: 'bot' }]);
          }, 5000);
          // The last conversation has been completed
          setTimeout(() => {
          setConversationCompleted(true);
        }, 25000);//longer timeout for the last message
          return;
        }
        
        setCurrentDialogueIndex(currentDialogueIndex + 1); // Move to the next dialogue
        setConversationIndex(conversationIndex + 1); // Move to the next conversation
        setConversation(currentScenario.conversation[conversationIndex+1]); // Set the next conversation   
      }
    }
}
};

  const sendMessage = (input) => {
    if (input.trim()) {
      if (input.length < 15) {
        setIsConfirmationOpen(true); // Open the confirmation modal if the message is too short
      } else {
      handleInput(input);
      setIsSendDisabled(true); // Disable the send button while bot is responding
      setInput('');
      }
    }
  };

  const confirmSend = () => {
    setIsSendDisabled(true);
    handleInput(input);
    setInput('');
    setIsConfirmationOpen(false); // Close the confirmation modal
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false); // Close the confirmation modal without sending the message
  };

  // Function to toggle an option in the multiselect
  const handleMultiSelectChange = (option) => {
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((o) => o !== option); // Uncheck
      } else {
        return [...prevSelected, option]; // Check
      }
    });
  };

  // Function to submit the multiselect choices
  const handleMultiSelectSubmit = () => {
    // Format the message with bullet points
    // Transform the selected options into an array of JSX elements with bullet points
    const messageElements = selectedOptions.map((o, index) => (
      <React.Fragment key={index}>
        • {o}
        <br />
      </React.Fragment>
    ));
    setSelectedOptions([]);
    handleInput(messageElements);
  };



  const getRelationEmoji = (relation) => {
    switch (relation) {
      case 'Parent':
        return '🏠'; 
      case 'Healthcare practitioner':
        return '🩺'; 
      case 'Friend':
        return '🗣️'; 
      default:
        return '👾'; // Default robot emoji
    }
  };

  const makeInstructionBold = (text) =>{
    const keyword = 'Instructions:';
    const splitIndex = text.indexOf(keyword);
    if (splitIndex !== -1) {
      return (
        <>
        {text.substring(0, splitIndex)}
        <br />
        <br />
        <i><strong>{text.substring(splitIndex)}</strong></i>
        </>
      );
    }
    return text;
  };
  // Function to toggle instructions overlay
  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  const finishActivity = () => {
    // Call the onCompletion function passed as a prop
    if(onCompletion){
      onCompletion();
    }
    
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  
  useEffect(scrollToBottom, [messages]);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Flex direction="column" width="100%" height="70vh" >
      <VStack spacing={1} align="center" marginTop={6}>
      <Heading size="lg">{name}</Heading> 
      <Button colorScheme="purple" onClick={toggleInstructions}>
          Activity Instructions🧭
        </Button>
      </VStack>
      <InstructionsOverlay isOpen={showInstructions} onClose={toggleInstructions} name={name} description={description} instructions={instructions} />

    <Flex padding="2em" flexGrow={1} width="100%" alignItems="center" justifyContent="center">
       
    
    <Box width="30%" mt="1em" bg="white" p={4} height="65vh" boxShadow="xl" borderRadius="lg" overflow="auto">
    
      
      <Box bg="blue.500" px={4} py={4} color="white">
      <HStack justifyContent="space-between" >
          <HStack>
          <FontAwesomeIcon icon={faAngleLeft}/>
          <FontAwesomeIcon icon={faAngleLeft} visibility="hidden"/>
          </HStack>
            <Heading size="sm">{showAvatar && userResponses.relation && beginConversation? userResponses.relation : 'Roleplay Chatbot'}</Heading>
            
          <HStack>
            <FontAwesomeIcon icon={faPhone} />
            <FontAwesomeIcon icon={faVideo} />
          </HStack>
        </HStack>
      </Box>
      <VStack spacing={4} align="stretch" height="90%">
        <Box flex="1" overflowY="scroll" paddingBottom="1em">
        {messages.map((message, index) => (
            <Flex
            key={index}
            justify={message.sender === 'user' ? 'flex-end' : 'flex-start'}
            mx={3}
            my={2}
            >
            { showAvatar && message.sender === 'bot' && userResponses.relation ? (
              <Box
              p="2"
              bg="gray.100" 
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              boxShadow="0 2px 4px 0 rgba(0, 0, 0, 0.05)"
              w={10}
              h={10}
              mr={3} // Margin right for spacing between icon and message
            >
               {getRelationEmoji(userResponses.relation)}
              </Box>
            ): null}
            <Box
                bg={message.sender === 'user' ? 'blue.500' : 'gray.300'}
                borderRadius="lg"
                px={3}
                py={2}
                maxWidth="70%"
            >
                <Text color={message.sender === 'user' ? 'white' : 'black'}>
                {makeInstructionBold(message.text)}
                </Text>
            </Box>
            </Flex>
        ))}
        {/* Empty div used to scroll into view when a new message is added */}
        <div ref={messagesEndRef} />
        </Box>
        
            {/* Conditional rendering of options or text input */}
            {!isMultiSelect&&!isFreeResponse && options.length > 0 ? (
              <Stack direction="column" align="stretch" spacing={2}>
                {options.map((option, index) => (
                  <Button
                    key={index}
                    onClick={() => handleInput(option)}
                    colorScheme="teal"
                    variant="outline"
                    width="full"
                    flex="1"
                    whiteSpace="normal"
                    textAlign="left"
                    paddingX={4}
                    paddingY={2}
                    minHeight="3em"
                  >
                    {option}
                  </Button>
                ))}
              </Stack>
            ) : (
              <HStack as="form" onSubmit={(e) => { e.preventDefault(); sendMessage(input); }} spacing={2} px={2} pb={2}>
                <Input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Type your response..."
                  borderRadius="full"
                />
                <Button onClick={() => sendMessage(input)} colorScheme="blue" borderRadius="full" isDisabled={isSendDisabled}>Send</Button>
              </HStack>
        )}
      </VStack>
        
    </Box>
    {isMultiSelect && options.length > 0 && isCheckBoxVisible &&(
        <Box p={4} ml="5em" bg="white" boxShadow="xl" borderRadius="lg" width="300px">
          <VStack spacing={4} textAlign="left">
            {options.map((option, index) => (
              <Checkbox
                key={index}
                isChecked={selectedOptions.includes(option)}
                onChange={() => handleMultiSelectChange(option)}
              >
                {option}
              </Checkbox>
            ))}
            <Button onClick={handleMultiSelectSubmit} colorScheme="blue">Submit</Button>
          </VStack>
          </Box>
      )}
      {conversationCompleted && (
        <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        zIndex="1000" // Ensure it's above everything else
      >
        <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Box p={8} bg="white" alpha="0.6" borderRadius="lg" boxShadow="xl">
          <VStack spacing={4}>
            
            <Text fontSize="xl" fontWeight="bold" >Activity Completed!</Text>
            <Button colorScheme="teal" onClick={finishActivity}>Finish</Button>
            
          </VStack>
         </Box>
        </Center>
        </Box>
      )}
   
    </Flex>
    <ConfirmationModal isOpen={isConfirmationOpen} onClose={closeConfirmation} onConfirm={confirmSend} />
    </Flex>
  );
};

export default Roleplay;
