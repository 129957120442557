import React, { useState } from 'react';
import {
    Box, Flex, Text, Button, 
    useColorModeValue, Container, IconButton, Collapse, Stack,
    useBreakpointValue,
    Menu, MenuButton, MenuItem, MenuList, Heading
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon, ChevronDownIcon, } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHouse } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../auth/AuthContext';
import {useTheme} from '../ThemeContext/ThemeContext';

const Navbar = () => {
    const { currentTheme } = useTheme();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { user, logout } = useAuth();
   
    // Update username display logic to use the user object from context
    const username = user ? user.username : '';
    
    // Adjust logout method to use the logout function from context
    const handleLogout = () => {
    logout();
    window.location.reload(); // Force a reload of the page
    };

    const bgColor =currentTheme.color5;
    const textColor = useColorModeValue('white', 'gray.800');
    //const buttonColor = useColorModeValue('purple.500', 'purple.200');
    const isMobile = useBreakpointValue({ base: true, md: false });

    const MenuItems = ({ children, to }) => (
        <Button
        as={Link}
        to={to}
        variant="ghost"
        colorScheme="purple"
        color={textColor} 
        _hover={{
            bg: currentTheme.color1, 
            color: 'white', 
        }}
        >
            {children}
        </Button>);

      const UserMenu = () => (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} 
            colorScheme={bgColor} 
            color={textColor}
            _hover={{ 
                bg: currentTheme.color1,
                color: 'white' }} >
                <FontAwesomeIcon icon={faUser} style={{ padding: '0 10px 0 0' }} />
                {username}
            </MenuButton>

            <MenuList>
                {/* Example DropdownItem, add more or modify as needed */}
                <MenuItem 
                    as={Link}
                    to="/profile" 
                    colorScheme={bgColor}
                    color={bgColor} 
                    _hover={{
                        bg: currentTheme.color1, 
                        color: 'white', 
                    }}>Profile</MenuItem>
                <MenuItem 
                    as={Link}
                    to="/resources" 
                    colorScheme={bgColor}
                    color={bgColor} 
                    _hover={{
                        bg: currentTheme.color1, 
                        color: 'white', 
                    }}>Resources for You</MenuItem>
                <MenuItem 
                    colorScheme={bgColor}
                    color={bgColor} 
                    _hover={{
                        bg: currentTheme.color1, 
                        color: 'white', 
                    }}
                    onClick={() => {
                        handleLogout()
                    }}>Logout</MenuItem>
            </MenuList>
        </Menu>
    );

    return (
        <Box bg={bgColor} px={20}  color="white" position="relative" zIndex ="10" borderRadius="30px 30px 10px 10px" boxShadow='md'>
            <Container maxW="container.xl">
                <Flex h={16} alignItems={'center'} justifyContent="space-between">
                    <Flex align="center" mr={3}>
                        <Heading fontSize="xl" fontWeight="bold"fontFamily="monospace" lineHeight="taller">
                        🏳️‍⚧️Gender Affirming, Life Affirming🏳️‍⚧️
                        </Heading>
                    </Flex>
                    {!isMobile && (
                    <Flex display={{ base: 'none', md: 'flex' }}>
                        <MenuItems to="/"><FontAwesomeIcon icon={faHouse}style={{ padding: '0 10px 0 0' }} />Home</MenuItems>                       
                        <MenuItems to="/map">Map</MenuItems>
                        <MenuItems to="/activities">Activities</MenuItems>
                        
                        <MenuItems to="/support">Support</MenuItems>

                        <MenuItems to="/contact">Contact</MenuItems>
                        <MenuItems to="/about">About</MenuItems>
                        {username ? <UserMenu /> : 
                        <MenuItems to="/login"><FontAwesomeIcon icon={faUser} style={{ padding: '0 10px 0 0' }} />Log In</MenuItems>}
                    </Flex>
                    )}
                   
                    <IconButton
                    size={'md'}
                    icon={isMobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Toggle Navigation'}
                    display={{ md: 'none' }} // only display on mobile or small screen
                    onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}/>
                        
                </Flex>
                <Collapse in={isMobile && isMobileMenuOpen} animateOpacity>
                <Stack
                    bg={bgColor}
                    p={20}
                    color={textColor}
                    >
                    <MenuItems to="/">Home</MenuItems>
                    <MenuItems to="/map">Map</MenuItems>
                    <MenuItems to="/quiz">Quiz</MenuItems>
                    <MenuItems to="/activities">Activities</MenuItems>
                    <MenuItems to="/support">Support</MenuItems>
                    <MenuItems to="/contact">Contact</MenuItems>
                    <MenuItems to="/about">About</MenuItems>
                    {username ? <UserMenu /> : <MenuItems to="/login"> <FontAwesomeIcon icon={faUser} style={{ padding: '0 10px 0 0' }} />Log In</MenuItems>}
                </Stack>
                </Collapse>
            </Container>
        </Box>
    );
};
export default Navbar;