import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const DialogueQuestionOverlay = ({ isOpen, onClose, leastDestructiveRef, title, description, redirectPath }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const handleClose = () => {
    onClose();
    toast({
      title: 'Notification',
      description: description,
      status: 'info',
      duration: 9000,
      isClosable: true,
    });
  };

  const handleConfirm = () => {
    navigate(redirectPath);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>
            {description}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={leastDestructiveRef} onClick={handleClose}>
              No
            </Button>
            <Button colorScheme="blue" onClick={handleConfirm} ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DialogueQuestionOverlay;
