import React from 'react'
import './Box.css'
import ContainerBackground from '../ContainerBackground/ContainerBackground'
import Navbar from '../Navbar/Navbar'
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {useTheme} from '../ThemeContext/ThemeContext';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gqIcon from '../../Assets/gq-icon.svg'
import nbIcon from '../../Assets/nb-icon.svg'
import transIcon from '../../Assets/trans-icon.svg'
import PanicButton from '../PanicButton/PanicButton';
import ThemeMenuButton from '../ThemeMenuButton/ThemeMenuButton';


function Box({ children }) {
    const { currentTheme, switchTheme } = useTheme();

  return (
    <div>
        <ContainerBackground theme={currentTheme}>
        <Navbar/>
        <div className='boxContainer'>
        
        <div className = 'pinkCard'>
            <div className = 'blueCard'>
            {children}
            </div>
        </div>
        </div>
        <ThemeMenuButton/>
        <PanicButton />
        </ContainerBackground>
    </div>
  );
};

export default Box

