import React, { useState, useEffect, useCallback } from 'react';
import { 
    Container, 
    Text, 
    Box, 
    Link, 
    useColorModeValue,
    IconButton, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Button,
    useToast
    } from '@chakra-ui/react';
    
import { CloseIcon } from '@chakra-ui/icons';
import { useAuth } from '../auth/AuthContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const ResourcesPage = () => {
    const serverUrl = process.env.REACT_APP_BACKEND_URL;
    const [resources, setResources] = useState([]);
    const [userName, setUserName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [resourceToRemove, setResourceToRemove] = useState(null);
    const { user } = useAuth();
    // Set background color based on the color mode
    const bgColor = useColorModeValue('gray.50', 'gray.700');
    const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
    const toast = useToast();   

    const getResourceIds = useCallback((token) => {
        fetch(`${serverUrl}/userResource`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to get resources.');
            }
            return response.json();
        })
        .then(data => {
            //setResourcesId(data.resources); // Assuming the data is the array of resources
            fetchResources(data.resources);
        })
        .catch(error => {
            setError(error.message);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        const fetchProfile = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setError("Please log in to view your personalized resource list.");
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch(`${serverUrl}/userProfiles`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                if (!response.ok) {
                    throw new Error("It seems you don't have an account yet. Please log in or sign up.");
                }

                const data = await response.json();
                setUserName(data.name);
                getResourceIds(token); // Fetch resources after successful profile fetch
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, [user, getResourceIds]); // Dependency on user context

    const fetchResources = (ids) => {
        fetch(`${serverUrl}/resourceList.json`)
        .then(response => response.json())
        .then(allResources => {
            // Filter to include only resources with ids that are in the ids array
            const filteredResources = allResources.filter(resource => ids.includes(resource.id));
            //console.log(filteredResources)
            // Grouping resources by topic
            const groupedResources = filteredResources.reduce((acc, resource) => {
                acc[resource.topic] = acc[resource.topic] || [];
                acc[resource.topic].push(resource);
                return acc;
            }, {});
    
            setResources(groupedResources);
            //console.log(groupedResources)
        })
        .catch(error => {
            setError(error.message);
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const openRemoveModal = (resource) => {
        setResourceToRemove(resource);
        setIsModalOpen(true);
      };
    
    const closeRemoveModal = () => {
    setResourceToRemove(null);
    setIsModalOpen(false);
    };
    const handleRemoveResource = () => {
        const token = localStorage.getItem('token');
        if (!resourceToRemove) return;
    
        fetch(`${serverUrl}/userResource`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to get resources.');
            }
            return response.json();
          })
          .then(data => {
            const updatedResources = data.resources.filter(id => id !== resourceToRemove.id);
            return fetch(`${serverUrl}/userResource`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({ resources: updatedResources })
            }).then(() => updatedResources); // Pass the updatedResources to the next .then block
          })
          .then((updatedResources) => {
            fetchResources(updatedResources);
            closeRemoveModal();
            toast({
                title: "Resource Removed",
                description: "The resource has been successfully removed from your list.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top"
            });
          })
          .catch(error => {
            setError(error.message);
            toast({
                title: "Error",
                description: "Failed to remove the resource, please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top"
            });
          });
      };
        
    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <Text color="red.500">{error}</Text>;
    }

    return (
        <Container maxW="container.lg" my={20}>
            <Text fontSize="2xl" mb="6" fontWeight="semibold">Welcome {userName}! Here are your customized resources</Text>
            {Object.keys(resources).length > 0 ? (
                Object.entries(resources).map(([topic, resourcesList]) => (
                    <Box key={topic} mb={12}>
                        <Text fontSize="3xl" mb={5} fontWeight="bold" color="teal.500">{topic}</Text>
                        {Array.isArray(resourcesList) ? resourcesList.map(resource => (
                            <Box key={resource.id} p={5} shadow="lg" borderWidth="1px" mb={5}
                                 bgColor={bgColor} _hover={{ bg: hoverBgColor }} transition="background-color 0.3s"
                                 cursor="pointer" position="relative" onClick={() => window.open(resource.link, '_blank')}>
                                <Text fontSize="xl" fontWeight="medium">{resource.label}</Text>
                                <Text>{resource.description}</Text>
                                <IconButton
                                aria-label="Remove resource"
                                icon={<CloseIcon />}
                                size="sm"
                                position="absolute"
                                top="5px"
                                right="5px"
                                onClick={(e) => { e.stopPropagation(); openRemoveModal(resource); }}
                                />
                            </Box>
                        )) : <Text>There is an issue displaying resources.</Text>}
                    </Box>
                ))
            ) : (
                <Text fontSize="xl">It seems like you haven't added any resources in your resource list. Keep exploring the world to grow your list of resources! 🙌</Text>
            )}
             <Modal isOpen={isModalOpen} onClose={closeRemoveModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Remove Resource</ModalHeader>
                    <ModalBody>
                    <Text>Are you sure you want to remove this resource from your list?</Text>
                    </ModalBody>
                    <ModalFooter>
                    <Button colorScheme="red" onClick={handleRemoveResource}>Remove</Button>
                    <Button ml={3} onClick={closeRemoveModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default ResourcesPage;
