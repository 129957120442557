import { useContext, useEffect } from 'react';
import { useWorldData as useContextWorldData } from '../context/WorldContext';

const useWorldData = () => {
  const { currentWorldOrder, setCurrentWorldOrder, currentWorldId, setCurrentWorldId } = useContextWorldData();

  useEffect(() => {
    // Load from localStorage if available, otherwise use initial context state
    const storedWorldOrder = localStorage.getItem('currentWorldOrder');
    const storedWorldId = localStorage.getItem('currentWorldId');
    // Only update context if values from localStorage exist and are different
    if (storedWorldOrder && parseInt(storedWorldOrder, 10) !== currentWorldOrder) {
      setCurrentWorldOrder(parseInt(storedWorldOrder, 10));
    }

    if (storedWorldId && storedWorldId !== currentWorldId) {
      setCurrentWorldId(storedWorldId);
    }

  }, []); // Run only once on mount to load data

  useEffect(() => {
    if (currentWorldOrder !== null) {
      localStorage.setItem('currentWorldOrder', currentWorldOrder);
    }
    if (currentWorldId !== null) {
      localStorage.setItem('currentWorldId', currentWorldId);
    }
  }, [currentWorldOrder, currentWorldId]); // Sync only when these values change
  
  return { currentWorldOrder, setCurrentWorldOrder, currentWorldId, setCurrentWorldId };
};

export default useWorldData;
