import React, { createContext, useContext, useState, useEffect } from 'react';

const WorldContext = createContext();

export const WorldProvider = ({ children }) => {
  const [currentWorldOrder, setCurrentWorldOrder] = useState(() => {
    const savedOrder = localStorage.getItem('currentWorldOrder');
    return savedOrder ? parseInt(savedOrder, 10) : null;
  });
  const [currentWorldId, setCurrentWorldId] = useState(() => {
    const savedId = localStorage.getItem('currentWorldId');
    return savedId || null;
  });
  useEffect(() => {
    localStorage.setItem('currentWorldOrder', currentWorldOrder);
  }, [currentWorldOrder]);

  useEffect(() => {
    localStorage.setItem('currentWorldId', currentWorldId);
  }, [currentWorldId]);

  return (
    <WorldContext.Provider value={{ currentWorldOrder, setCurrentWorldOrder, currentWorldId, setCurrentWorldId }}>
      {children}
    </WorldContext.Provider>
  );
};

export const useWorldData = () => {
  return useContext(WorldContext);
};
