import { useState, useEffect, useCallback } from 'react';

const useUserProfile = (serverUrl, token) => {
  const [userProfileExists, setUserProfileExists] = useState(false);

  const fetchUserProfile = useCallback(async () => {
    if (!token) return;
    try {
      const response = await fetch(`${serverUrl}/userProfiles`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        throw new Error('Unauthorized access - invalid token');
      }
      if (!response.ok) throw new Error('Failed to fetch user profile');
      const data = await response.json();
      if (data && Object.keys(data).length !== 0) {
        setUserProfileExists(true);
      }else {
        setUserProfileExists(false);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setUserProfileExists(false);
    }
  }, [token, serverUrl]);

  useEffect(() => {
    if (token) fetchUserProfile();
  }, [token, fetchUserProfile]);

  return { userProfileExists, setUserProfileExists };
};

export default useUserProfile;
