import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, VStack, Text } from '@chakra-ui/react';

const InstructionsOverlay = ({ isOpen, onClose, name, description, instructions}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl"> 
      <ModalOverlay />
      <ModalContent m={4} p={6}>
        <ModalHeader align="center">{name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <VStack spacing={4} align="center" marginBottom={6}>
                <Text fontSize="xl" textAlign="center">{description}</Text>
                <Text fontSize="md" textAlign="center" fontStyle="italic">{instructions}</Text>
            </VStack>

        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default InstructionsOverlay;