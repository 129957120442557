import React from 'react';
import { Box, Container, Text, Heading, useColorModeValue, Link } from '@chakra-ui/react';

function Contact() {
  const bg = useColorModeValue("teal.50", "teal.900");
  const color = useColorModeValue("gray.800", "white");

  return (
    <Container maxW="xl" centerContent p={4}>
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        borderRadius="lg"
        bg={bg}
        color={color}
        w="100%"
        mt={5}
        mb={5}
        textAlign="center"
      >
        <Heading fontSize="2xl" mb={4}>Contact Us</Heading>
        <Text fontSize="xl">
          For more information, please email us at:
        </Text>
        <Link href="mailto:giantyouthproject@gmail.com" isExternal fontSize="lg" mt={2} fontWeight="bold" color="teal.600">
          giantyouthproject@gmail.com
        </Link>
      </Box>
    </Container>
  );
}

export default Contact;