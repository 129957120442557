import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import './LoginFullScreen.css'
import ContainerBackground from '../ContainerBackground/ContainerBackground'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, useToast, Text, Stack, Menu, MenuButton, MenuList, MenuItem} from '@chakra-ui/react';
import {
    Input,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    InputGroup,
    InputRightElement
  } from '@chakra-ui/react'
import { ArrowRightIcon, ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gqIcon from '../../Assets/gq-icon.svg'
import nbIcon from '../../Assets/nb-icon.svg'
import transIcon from '../../Assets/trans-icon.svg'
import PanicButton from '../PanicButton/PanicButton';
import {useTheme} from '../ThemeContext/ThemeContext';
import ThemeMenuButton from '../ThemeMenuButton/ThemeMenuButton';
import { useAuth } from '../auth/AuthContext';

function Login2() {
    const serverUrl = process.env.REACT_APP_BACKEND_URL;
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const { updateAuthState } = useAuth();
    //Switching themes
    const { currentTheme, switchTheme } = useTheme();

    //Navigating to signup
    const navigate = useNavigate();

    const handleSignUpRedirect = () => {
      navigate('/signup'); // Use the navigate function to redirect
    };

    const handleUserNameChange = (e) => setUserName(e.target.value)
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
      };

    const isError = userName === ''
    
    //Password validation
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    //Submit details
    const toast = useToast();
    const handleSubmit = async (event) => {
        event.preventDefault();
        const userData = {
          username: userName,
          password: password,
      };
      //try to login with the user data
      try {
        const response = await fetch(`${serverUrl}/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });
        const data = await response.json();
    
        if (data.token) {
    
          localStorage.setItem('token',data.token)
          if (updateAuthState) updateAuthState(data.token);
    
          toast({
            title: 'Successfully Logged In',
            description: data.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate('/');
    
        } else {
          toast({
            title: 'Login Failed! Please check User Name or Password',
            description: data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: 'There was a problem with your log-in',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });

        console.error('Log-in error:', error);
      }
      };

      const handleGoBack = () => {
        navigate('/');
      };

  return (
        <div className="row">
            <div className= "col-7 login-container" style={{ backgroundColor: `${currentTheme.color1}1A` }}>
            <div className='go-back-btn'>
              <Button colorScheme="purple"onClick={handleGoBack}>Go Back To Home</Button>
            </div>
                <div className='loginBox'>
                    <form onSubmit={handleSubmit}>
                      <div className='logo'>
                          <Text className='logo-text' as='b' fontSize='3xl' style={{ color: 'black', textShadow: " 1px  black" }}>Gender-Affirming, Life-Affirming</Text>
                      </div>
                      <Stack className='login-text' spacing={0}>
                        <Text as='b' fontSize='xl' style={{ color: 'black', fontWeight: 'bold'  }}>Welcome back!</Text>
                        <Text fontSize='lg' as='b' style={{ color: 'black', fontWeight: 'bold' }}>Login and continue your journey 🤗 </Text>
                      </Stack>
                        <div className='login-form'>
                        <FormControl className="centered-input" isInvalid={isError} isRequired>
                            <Input type='text' size='md' placeholder='Enter Username' value={userName} onChange={handleUserNameChange} />
                                {!isError ? (
                                    <FormHelperText>

                                    </FormHelperText>
                                ) : (
                                <FormErrorMessage>User is required.</FormErrorMessage>
                                    )}
                        </FormControl>
                        <InputGroup className="centered-input" size='md'>
                                <Input
                                    pr='4.5rem'
                                    type={show ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    onChange={handlePasswordChange}
                                />
                                <InputRightElement width='4.5rem'>
                                    <Button h='1.75rem' size='sm' onClick={handleClick}>
                                        {show ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                        </InputGroup>
                        </div>
                        <Button mt={4} type='submit' style={{ backgroundColor: currentTheme.color1 }} width="70%" >
                            Log In&nbsp;<ArrowForwardIcon boxSize={4}/>
                        </Button>
                    </form>
                </div>
            </div>

            <div className= "col-5">
            <ContainerBackground theme={currentTheme}>
                <div className= "centered-content">
                    <Text fontSize='4xl'>Do not have an account yet?</Text>
                    <Button mt={4} colorScheme="purple" width="60%" onClick={handleSignUpRedirect}>
                        Sign Up&nbsp;<ArrowRightIcon boxSize={3}/>
                    </Button>
                </div>
                <ThemeMenuButton />
                <PanicButton />
                </ContainerBackground>
            </div>
        </div>
  )
}

export default Login2
