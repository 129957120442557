import React from 'react'
import '../Box/Box.css'
import ContainerBackground from '../ContainerBackground/ContainerBackground'
import Navbar from '../Navbar/Navbar'
import { Button, Menu, MenuButton, MenuList, MenuItem, position } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {useTheme} from '../ThemeContext/ThemeContext';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gqIcon from '../../Assets/gq-icon.svg'
import nbIcon from '../../Assets/nb-icon.svg'
import transIcon from '../../Assets/trans-icon.svg'
import PanicButton from '../PanicButton/PanicButton';
import ThemeMenuButton from '../ThemeMenuButton/ThemeMenuButton';



function ActivityContainer({ children }) {
    const { currentTheme, switchTheme } = useTheme();

  return (
    <div>
        <ContainerBackground theme={currentTheme}
        style={{ 
            display: "flex",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            }}>
    
        <div style={{maxWidth:"75%", margin: "0 auto"}}>
        <Navbar/>
        
            {children}
            
        </div>
        <ThemeMenuButton/>
        <PanicButton style={{marginBottom: "2rem"}}/>
        </ContainerBackground>
    </div>
  );
};

export default ActivityContainer

