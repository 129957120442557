import { useLocation, useNavigate } from 'react-router-dom';
import DragDrop from '../DragDrop/DragDrop';
import DiagramActivity from '../DiagramActivity/DiagramActivity'
import { useState, useEffect } from 'react';
import ActivityContainer from '../ActivityContainer/ActivityContainer';
import Roleplay from '../Roleplay/Roleplay';
import Graphic from '../Graphic/Graphic';
import { useAuth } from '../auth/AuthContext';
import useWorldData from '../../hooks/useWorldData';

const ActivityPage = () => {
  const location = useLocation();
  const { activityData, from } = location.state;
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const { user } = useAuth();
  const {currentWorldOrder, setCurrentWorldOrder, currentWorldId, setCurrentWorldId} = useWorldData();
  const worldOrder = currentWorldOrder;
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
 
  const [worldId, setWorldId] = useState(null);
  const [progressDialogue, setProgressDialogue] = useState(null);

  useEffect(() => {
    // Update isLoggedIn state based on user object
    setIsLoggedIn(!!user); // If user is truthy, set isLoggedIn to true
  }, [user]);

  const handleActivityCompletion = async () => {
    if(isLoggedIn){
      try {
        // Fetch current progress
        const progressResponse = await fetch(`${serverUrl}/userProgress/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (!progressResponse.ok) {
          throw new Error(`Failed to fetch progress: ${progressResponse.status}`);
        }
  
        const progress = await progressResponse.json();
  
        //get the world order and next dialogue id
        if(progress.lastWorld && progress.lastDialogue){
          
          //setWorldOrder(worldProgress.order);
          setWorldId(progress.lastWorld); //need to get the world order by world id
          setProgressDialogue(progress.lastDialogue);
          
        }
      
  
        // Update progress for the activity
  
        const updatedActivityProgresses = progress.activityProgresses.map(activity => {
          if (activity.name === activityData.name) {
            return { ...activity, unlocked: true };
          }
          return activity;
        });
  
        
  
        // Prepare updated progress data
        const updatedProgressData = {
          ...progress,
          activityProgresses: updatedActivityProgresses,
          
          status: 'inProgress' 
        };

  
        // Send updated progress to the server
        const updateResponse = await fetch(`${serverUrl}/userProgress/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(updatedProgressData)
        });
  
        if (!updateResponse.ok) {
          throw new Error(`Failed to update progress: ${updateResponse.status}`);
        }
  
        if(from==='World'){
          // Navigate back to World with nextDialogueId from progress
          navigate('/world', { state: { worldOrder: worldOrder, progressDialogue: progress.lastDialogue } });
        }else {
          navigate('/activities');
        }
        
  
      } catch (error) {
        console.error('Error updating progress:', error);
      }
    } else if (!isLoggedIn) {
      if(from==='World'){
        // Get lastWorld and lastDialogue from localStorage
        const lastDialogue = localStorage.getItem('lastDialogue');
        // Navigate back to World with nextDialogueId from progress
        navigate('/world', { state: { worldOrder: worldOrder, progressDialogue: lastDialogue } });
      }
    }
  };

  // Switch case to render based on the type of activity
  switch (activityData.type) {
    case 'roleplay':
      return (
        <ActivityContainer>
        <Roleplay
          activityData={activityData.content}
          onCompletion={handleActivityCompletion}
          name={activityData.displayName} 
          description={activityData.description}
          instructions={activityData.instructions}
        />
        </ActivityContainer>
      );
    case 'match':
      return (
        <ActivityContainer>
          <DragDrop 
          activityData={activityData.content} 
          onCompletion={handleActivityCompletion}
          name={activityData.displayName} 
          description={activityData.description}
          instructions={activityData.instructions} />
        </ActivityContainer>
      );
    case 'graphic':
    return <ActivityContainer> 
        <Graphic 
        activityData={activityData.content} 
        onCompletion={handleActivityCompletion} 
        name={activityData.displayName} 
        description={activityData.description} />
        </ActivityContainer>;
    case 'interactiveDiagram':
      return <ActivityContainer>
        <DiagramActivity 
        activityData={activityData.content} 
        onCompletion={handleActivityCompletion}
        name={activityData.displayName} 
        description={activityData.description}
        instructions={activityData.instructions}/>
      </ActivityContainer>
    default:
      return <p>No activity found</p>;
  }
};

export default ActivityPage;
