import React from "react";
import { Box, Container, Heading, Text, useColorModeValue, Icon, Image } from "@chakra-ui/react";
import { Fade } from "@chakra-ui/transition";
import { FaTransgenderAlt, FaHeart, FaUserFriends, FaSeedling } from 'react-icons/fa';
import "bootstrap/dist/css/bootstrap.min.css";

const About = () => {
  const bg = useColorModeValue("purple.50", "purple.900");
  const cardBackground = useColorModeValue("white", "gray.700");
  const color = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("purple.200", "purple.700");

  return (
    <Fade in={true}>
      <Box p={50}>
        <Container maxW="800px">
          <Box bg={bg} borderRadius="lg" p={6} mb={8} border="1px" borderColor={borderColor}>
            <Icon as={FaTransgenderAlt} w={10} h={10} color="purple.500" mb={4} />
            <Heading as="h1" mb={4} fontSize="3xl" textAlign="center" color="purple.500">
              Gender-Affirming, Life-Affirming
            </Heading>
            <Text mb={4} fontSize="lg" color={color} textAlign="center">
              An online puberty and sexual health resource for gender-independent,
              nonbinary, transgender, and intersex youth.
            </Text>
          </Box>

          <Box bg={cardBackground} borderRadius="lg" p={6} mb={8} boxShadow="lg">
            <Icon as={FaHeart} w={8} h={8} color="red.500" mb={4} />
            <Heading as="h2" mb={4} fontSize="2xl" color="purple.600">An Online Educational Resource:</Heading>
            <Text mb={4} fontSize="md" color="gray.700">
              Welcome! This website was created as a resource about puberty health
              and sexual education for transgender, non-binary, gender-diverse, and
              intersex youth. Throughout this website you will see the acronym
              GIaNT, which is used as a placeholder for these identities. This
              website was created by a group of researchers who are experts on this
              content and members of the transgender community. They did their best
              to gather the highest quality information available and make it
              special for you. Resources for transgender, non-binary, gender-diverse,
              and intersex people have historically been limited and not easy to
              find. This website will provide you with customized resources that
              you can refer back to again and again.
            </Text>
          </Box>

          <Box bg={bg} borderRadius="lg" p={6} mb={8} border="1px" borderColor={borderColor}>
            <Icon as={FaUserFriends} w={8} h={8} color="green.500" mb={4} />
            <Heading as="h2" mb={4} fontSize="2xl" color="purple.600">Our Project:</Heading>
            <Text mb={4} fontSize="md" color={color}>
              The Social Innovation Research Centre, as well as
              our community partner, Action Canada for Sexual Health and Rights,
              have created an online interactive resource for gender-independent,
              intersex, non-binary, and trans (GIaNT) youth. This applied research
              project is titled: Gender-Affirming, Life-Affirming: Centering
              gender-independent, trans, nonbinary and intersex youth in puberty
              education with adaptive interactive media. It is funded by the Natural
              Sciences and Engineering Research Council of Canada (NSERC) through
              the College and Community Social Innovation Fund. The research is
              administered and supported by the Social Innovation Research Centre
              and the department of Applied Research, Innovation, and Entrepreneurship
              (ARIES) at Centennial College.<br/><br/>
              Current resources on puberty health that exist for GIaNT youth are
              limited to text-format. There is a large body of research that
              evidences the need for online resources for GIaNT youth because of
              their specialized needs and safety. This website provides an avenue
              to explore questions of identity and puberty and learn more about
              options for GIaNT youth. And it enables them to explore their own
              sense of self, and how their experiences of puberty make them feel
              with the recognition that puberty for GIaNT youth is often a
              different experience than for cisgender youth. Users will have the
              opportunity to connect these experiences with relevant health-related
              educational information from a range of sources.<br/><br/>
              The online resource works to resist stigmatization of gender
              exploration amongst existing puberty and sexual health education
              curriculums. It aims to provide information and gender exploration
              activities that are affirming for various (GIaNT) youth.
            </Text>
          </Box>
        </Container>
      </Box>
    </Fade>
  );
};

export default About;
