import React, { useState } from 'react';
import './DiagramActivity.css';
import { Tooltip, Button, VStack, Box, Text, Heading } from '@chakra-ui/react';
import InstructionsOverlay from '../InstructionsOverlay/InstructionsOverlay';

const DiagramActivity = ({ activityData, onCompletion, name, description, instructions }) => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [selectedPart, setSelectedPart] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current image index
  const [showInstructions, setShowInstructions] = useState(false);

  const handleMouseEnter = (part) => {
    setSelectedPart(part);
  };

  const handleMouseLeave = () => {
    setSelectedPart(null);
  };

  const finishActivity = () => {
    if (onCompletion) {
      onCompletion();
    }
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % activityData.graphic.length); // Cycle through images
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + activityData.graphic.length) % activityData.graphic.length); // Cycle backwards
  };
  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  return (
    <div className="activity-container">
      <VStack spacing={4} align="center" marginBottom={6}>
        <Heading as="h1" size="xl" textAlign="center">{name}</Heading>
        <Button colorScheme="purple" onClick={toggleInstructions}>Activity Instructions🧭</Button>
      </VStack>
      <InstructionsOverlay isOpen={showInstructions} onClose={toggleInstructions} name={name} description={description} instructions={instructions} />

      <div className="image-slider">
        <Button className="slider-btn" onClick={prevImage}>&lt;</Button> {/* Left navigation button */}
        <div className="diagram-container" style={{ position: 'relative' }}>
          <img src={`${serverUrl}${activityData.graphic[currentIndex].path}`} className="interactive-image" alt="Diagram" style={{ width: '100%' }} />
          {[...activityData.externalParts, ...activityData.internalParts].map(part => (
            part.graphicId === activityData.graphic[currentIndex].id && ( // Only render parts related to the current image
              <Tooltip
                label={
                  <div>
                    <p style={{ fontWeight: 'bold', fontSize: '1em', marginBottom: '4px' }}>{part.label}</p>
                    <p style={{ fontSize: '0.85em' }}>{part.description}</p>
                  </div>
                }
                fontSize="md"
                placement="auto"
                hasArrow
                bg="teal.600"
                color="white"
                p={3}
                key={part.label}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: `${part.rect.topLeft.x * 100}%`,
                    top: `${part.rect.topLeft.y * 100}%`,
                    width: `${part.rect.size.width * 100}%`,
                    height: `${part.rect.size.height * 100}%`,
                    backgroundColor: 'rgba(255,0,0,0.2)',
                    cursor: 'pointer'
                  }}
                  onMouseEnter={() => handleMouseEnter(part)}
                  onMouseLeave={handleMouseLeave}
                />
              </Tooltip>
            )
          ))}
        </div>
        <Button className="slider-btn" onClick={nextImage}>&gt;</Button> {/* Right navigation button */}
      </div>
      <Button colorScheme="teal" onClick={finishActivity}>Complete Activity</Button>
    </div>
  );
};

export default DiagramActivity;
