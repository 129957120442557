import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useToast,
  Heading
} from '@chakra-ui/react';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const {token} = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Password validation: at least 8 characters, one uppercase, one lowercase
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  
    if (!passwordRegex.test(newPassword)) {
      toast({
        title: 'Invalid Password',
        description: "Password must be at least 8 characters long and include both uppercase and lowercase letters.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
     // Check if passwords match
     if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Prevent form submission
    }
    try {
      const response = await fetch(`${serverUrl}/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword, resetToken: token})
      });
      const data = await response.json();
      if (response.ok) {
        toast({
          title: "Success",
          description: "Password reset successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate('/login');
      } else {
        throw new Error(data.message || 'Failed to reset password');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      minHeight="50vh"
      width="full"
      align="center"
      justifyContent="center"
    >
      <VStack
        as="form"
        p={8}
        borderWidth={1}
        borderRadius={8}
        bgColor="white"
        boxShadow="lg"
        spacing={4}
        width="full"
        maxW="md"
        onSubmit={handleSubmit}
      >
        <Heading size="lg">Reset Your Password</Heading>
        <FormControl isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            placeholder="Enter new password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Confirm New Password</FormLabel>
          <Input
            type="password"
            placeholder="Confirm new password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="blue"
          size="lg"
          fontSize="md"
          width="full"
        >
          Reset Password
        </Button>
      </VStack>
    </Flex>
  );
}

export default ResetPassword;
