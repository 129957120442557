// src/components/NotFound.js
import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p={4}
      my={20}
    >
      <Text fontSize="6xl" fontWeight="bold" mb={4}>
        404
      </Text>
      <Text fontSize="2xl" mb={4}>
        Oops! The page you're looking for doesn't exist.
      </Text>
      <Button colorScheme="teal" onClick={goHome}>
        Go Back Home
      </Button>
    </Box>
  );
};

export default NotFound;
