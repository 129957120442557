import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext({ user: null, logout: () => {}, updateAuthState: () => {} });


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const updateAuthState = (token) => {
    try{
    const decoded = jwtDecode(token); // Decode the token to get user data
    if(decoded){
      setUser(decoded); // Update the user state with decoded data
      localStorage.setItem('token', token); 
    }
  } catch (error) {
    console.error('Error decoding token:', error);
  }
    
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      updateAuthState(token); // Use the existing token to set the user state on initial load
    }
  }, []);

  const logout = () => {
    localStorage.clear(); // Clear the entire local storage
    setUser(null); // Clear the user state
  };

  return (
    <AuthContext.Provider value={{ user, logout, updateAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);