import React from 'react';
import styled from 'styled-components';

const black = '#000';
const white = '#fff';
const shadow = 'rgba(0,0,0,0.1)';
const px = '4px';

const Bubble = styled.div`
  position: relative;
  display: inline-block;
  margin: calc(5 * ${px});
  text-align: center;
  background-color: ${white};
  color: ${black};
  padding: calc(3 * ${px});
  box-shadow:
    0 calc(-1 * ${px}) ${white}, 
    0 calc(-2 * ${px}) ${black}, 
    ${px} 0 ${white}, 
    ${px} calc(-1 * ${px}) ${black}, 
    calc(2 * ${px}) 0 ${black}, 
    0 ${px} ${white}, 
    0 calc(2 * ${px}) ${black}, 
    calc(-1 * ${px}) 0 ${white}, 
    calc(-1 * ${px}) ${px} ${black}, 
    calc(-2 * ${px}) 0 ${black}, 
    calc(-1 * ${px}) calc(-1 * ${px}) ${black}, 
    ${px} ${px} ${black};
  width: 100%; // Changed from fixed width to percentage
  height: auto;
  max-width: 600px;
  font-family: 'Mukta', sans-serif; 
  font-weight: medium;
  font-size: 24px;
  line-height: 1.3em;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    top: calc(5 * ${px});
    left: calc(-2 * ${px});
    border: calc(0.7 * ${px}) solid transparent;
    border-right-color: ${white};
    box-shadow: 
      calc(-1 * ${px}) calc(-1 * ${px}) ${white}, 
      calc(-1 * ${px}) 0 ${white}, 
      calc(-2 * ${px}) 0 ${white}, 
      0 calc(-2 * ${px}) ${white}, 
      calc(-1 * ${px}) calc(1 * ${px}) ${black}, 
      calc(-2 * ${px}) calc(1 * ${px}) ${black}, 
      calc(-3 * ${px}) calc(1 * ${px}) ${black}, 
      calc(-4 * ${px}) calc(1 * ${px}) ${black}, 
      calc(-3 * ${px}) 0 ${black}, 
      calc(-2 * ${px}) calc(-1 * ${px}) ${black}, 
      calc(-1 * ${px}) calc(-2 * ${px}) ${black}, 
      0 calc(-1 * ${px}) ${black};
  }
`;

const SpeechBubble = ({ children }) => {
  return (
    <Bubble>
      {children}
    </Bubble>
  );
};

export default SpeechBubble;
