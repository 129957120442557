import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import TextCard from '../TextCard/TextCard';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Link,
  VStack,
  Center,
  useToast,
} from '@chakra-ui/react';

function Signup() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const [textDisplay, setTextDisplay] = useState(''); //toast fallback, paired with text card
  const [bgColor, setbgColor] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Username validation: at least 5 characters
    const usernameRegex = /^.{5,}$/;

    if (!usernameRegex.test(userName)) {
      toast({
        title: 'Invalid Username',
        description: "Username must be at least 5 characters long.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setTextDisplay("Username must be at least 5 characters long.");
      setbgColor('red');
      return;
    }

    // Password validation: at least 8 characters, one uppercase, one lowercase
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  
    if (!passwordRegex.test(password)) {
      toast({
        title: 'Invalid Password',
        description: "Password must be at least 8 characters long and include both uppercase and lowercase letters.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setTextDisplay("Password must be at least 8 characters long and include both uppercase and lowercase letters.");
      setbgColor('red');
      return;
    }
  
    if (password !== retypePassword) {
      toast({
        title: 'Error',
        description: "The passwords don't match",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setTextDisplay("The passwords don't match");
      setbgColor('red');
      return;
    }
  
    const userData = {
      username: userName,
      email: email,
      password: password,
    };
  
    // Continue with registration process
    try {
      const response = await fetch(`${serverUrl}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
      const data = await response.json();
  
      if (response.status === 200) {
        toast({
          title: 'Account Successfully Registered',
          description: data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setTextDisplay(data.message);
        setbgColor('green');
  
        navigate('/login');
      } else {
        toast({
          title: 'Registration Failed',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setTextDisplay(data.message);
        setbgColor('red');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was a problem with your registration',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setTextDisplay('There was a problem with your registration');
      setbgColor('red');
      console.error('Registration error:', error);
    }
  };

  return (
    <Flex
      minHeight="50vh"
      width="full"
      align="center"
      justifyContent="center"
      p={4} 
    >
      <VStack
        spacing={4}
        align="stretch"
        m={4}
        maxWidth="sm"
        minWidth="35vw"
        bgColor="white"
        padding={10}
        borderRadius={8}
        shadow="md"
      >
        <Center>
        
          <h2>Sign Up</h2>
        </Center>
        <form onSubmit={handleSubmit}>

          <FormControl isRequired>
            <FormLabel htmlFor="userName">User Name:</FormLabel>
            <Input
              id="userName"
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired mt={4}>
            <FormLabel htmlFor="email">Email:</FormLabel>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired mt={4}>
            <FormLabel htmlFor="password">Password:</FormLabel>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired mt={4}>
            <FormLabel htmlFor="retypePassword">Retype Password:</FormLabel>
            <Input
              id="retypePassword"
              type="password"
              value={retypePassword}
              onChange={(e) => setRetypePassword(e.target.value)}
            />
          </FormControl>
          <Button mt={4} colorScheme="purple" width="full" type="submit">
            Sign Up
          </Button>
        </form>
        <Link as={RouterLink} to="/login" color="teal.800" textAlign="center">I have an account</Link>
        {textDisplay && <TextCard text={textDisplay} bgColor={bgColor}  />}
      </VStack>
    </Flex>
  );
}

export default Signup;
