import React from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SimpleGrid } from '@chakra-ui/react';
import ClickableCard from '../ClickableCard/ClickableCard';
import Axolotl from '../../Assets/Images/Axolotl/Axolotl Gender.png';
import Finn from '../../Assets/Images/Characters/Finn/Finn_happy_light.png';
import Rio from '../../Assets/Images/Characters/Rio/Rio_Happy.png';
import MessengerSlimette from '../MessengerSlimette/MessengerSlimette';
import Confetti from 'react-confetti';
import './EndScreen.css';

const EndScreen = ({ isVisible }) => {
    const navigate = useNavigate();
    // Get dimensions for confetti
    const { width, height } = useWindowSize();

    if (!isVisible) return null;
    const goToMap = () => {
        navigate('/map');
    };

    return (
        <Box className="endScreenContainer" textAlign="center" p={5} rounded="md">
            <Confetti width={width} height={height} />
            <MessengerSlimette isVisible={true} message="Hurray!!! You completed this world" slimetteType={"entertained"} />
            <div className='row axolotl-container'>
            <img src={Axolotl} alt="Axolotl" style={{ width: '200px' }} />
            </div>
            <div className='row character-container'>
                <div className='col-6'>
                <img src={Finn} alt="Finn" style={{ width: '400px' }} />
                </div>
                <div className='col-6'>
                <img src={Rio} className="rio-img" alt="Rio" style={{ width: '400px' }} />
                </div>
            </div>
            <div className='row to-map-button-container'>
            <SimpleGrid columns={[1]} spacing="60px">
          <ClickableCard 
            key="start" 
            text="Go back to Map" 
            onClick={goToMap} 
            size="750px"
          />
        </SimpleGrid>
        </div>
        </Box>
    );

}
// Hook to get window size
function useWindowSize() {
    const [size, setSize] = React.useState({ width: window.innerWidth, height: window.innerHeight });
    React.useEffect(() => {
        const handleResize = () => {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
}

export default EndScreen;
