export const parseYesResources = (instruction) => {
    const regex = /@Yes\{([0-9, ]+)\}/;
    const match = instruction.match(regex);
    return match ? match[1].split(',').map(Number).filter(Boolean) : [];
  };
  
  export const extractResources = (inputString) => {
    const initialSplit = inputString.split('@resources{')[1];
    const regex = /@?([^{]+)\{([^}]+)\}/g;
    let match;
    const results = [];
    while ((match = regex.exec(initialSplit)) !== null) {
      const resourceString = `${match[1].trim()}{${match[2].trim()}}`;
      results.push(resourceString);
    }
    return results;
  };
  
  export const parseSpecialResources = (resources, selections) => {
    const ids = [];
    if (Array.isArray(resources)) {
      resources.forEach(resource => {
        selections.forEach(selection => {
          if (resource.startsWith(selection)) {
            const extractedIds = resource.match(/\{([^}]+)\}/)[1].split(', ').map(Number);
            ids.push(...extractedIds);
          }
        });
      });
    }
    return ids;
  };
  