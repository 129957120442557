import React from 'react';
import{ Text, LinkBox } from '@chakra-ui/react';

import {useTheme} from '../ThemeContext/ThemeContext';

const ClickableCard = ({text, onClick, href, size}) => {
    const { currentTheme } = useTheme();

    const bgColor=currentTheme.color2;
    return(
        <LinkBox
        as="article"
        maxW={size}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={4}
        bg={bgColor}
        boxShadow='md'
        _hover={{shadow: "lg"}}
        role="group"
        cursor="pointer"
        onClick={onClick}>
           
            <Text>{text}</Text>
            
        </LinkBox>

        );
};
export default ClickableCard;