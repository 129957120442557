import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import './WorldStart.css';
import TextBubble from '../TextBubble/TextBubble';
import FinnHappy from '../../Assets/Images/Characters/Finn/Finn_happy_light.png';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner2';
import useWorldData from '../../hooks/useWorldData';

function WorldStart() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const location = useLocation();
  const navigate = useNavigate();
  const selectedWorldType = location.state?.worldType || '';
  const [description, setDescription] = useState('');
  const [firstDialogueId, setFirstDialogueId] = useState(null);
  const [continueProgress, setContinueProgress] = useState(false);
  const [progressDialogue, setProgressDialogue] = useState(false);
  const token = localStorage.getItem('token');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Use world context
  const { currentWorldOrder, setCurrentWorldOrder, currentWorldId, setCurrentWorldId } = useWorldData();


  //Redirect to Map if trying to access directly
  useEffect(() => {
    if (selectedWorldType === '') {
      navigate('/map'); // Redirect to /map
    }
  }, [selectedWorldType, navigate]);

  const fetchWorldDetails = useCallback((currentWorldOrder) => {
    fetch(`${serverUrl}/worlds/${currentWorldOrder}`)
      .then(response => response.json())
      .then(data => {
        setCurrentWorldId(data._id);
        setDescription(data.description);
      })
      .catch(error => console.error('Error fetching description:', error));
  });

  useEffect(() => {
    switch (selectedWorldType) {
      case 'Introduction':
        setCurrentWorldOrder(0);
        fetchWorldDetails(0);
        break;
      case 'Key Terms':
        setCurrentWorldOrder(1);
        fetchWorldDetails(1);
        break;
        case 'Puberty Health/Gender exploration':
        setCurrentWorldOrder(2);
        fetchWorldDetails(2);
        break;
        case 'Social transition':
        setCurrentWorldOrder(3);
        fetchWorldDetails(3);
        break;
        case 'Medical Transition':
        setCurrentWorldOrder(4);
        fetchWorldDetails(4);
        break;
        case 'Legal Transition':
        setCurrentWorldOrder(5);
        fetchWorldDetails(5);
        break;
        case 'Sex Education':
        setCurrentWorldOrder(6);
        fetchWorldDetails(6);
        break;
        case 'STBBI’s':
        setCurrentWorldOrder(7);
        fetchWorldDetails(7);
        break;
        case 'Mental Health':
        setCurrentWorldOrder(8);
        fetchWorldDetails(8);
        break;
        case 'Community Connections':
        setCurrentWorldOrder(9);
        fetchWorldDetails(9);
        break;
        default:
          setCurrentWorldOrder(0);
          fetchWorldDetails(0);
        break;
    }
  }, [selectedWorldType, fetchWorldDetails]);

  useEffect(() => {
    if (token && currentWorldId) {
      setIsLoggedIn(true);
      progressChecker();
    } else {
      setIsLoggedIn(false);
    }
  },[token,currentWorldId] );

  //Checking the if progress exists
  const progressChecker = useCallback(() => {
    fetch(`${serverUrl}/userProgress/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json())
    .then(data => {
      if (data && Array.isArray(data.worldProgresses)) {
        // Find the correct world progress by matching currentWorldOrder
        const worldProgress = data.worldProgresses[currentWorldOrder];
        console.log('currentWorldOrder:', currentWorldOrder);
        console.log(data);
        console.log('worldProgress:', worldProgress);
        // Ensure progress exists for the correct world
        if (worldProgress && worldProgress.status === 'inProgress') {
          console.log('Progress exists');
          setContinueProgress(true);
          setProgressDialogue(worldProgress.nextDialogue);
        } else {
          console.log('No progress');
          setContinueProgress(false);
          setProgressDialogue(null);
        }
      }
    })
      .catch(error => console.error('Error fetching dialogue ID:', error));
  }, [token, currentWorldId]);
    

  //Function that redirects to World beginning or continue progress
  const redirectToWorld = (status) => {
    if (status === "begin"){
      if (isLoggedIn) {
        setProgressDialogue(null);
        const progressData = {
          lastWorld: currentWorldId,
          lastDialogue: firstDialogueId, 
          status: "inProgress",
          worldProgresses: [{
            world: currentWorldId,
            nextDialogue: firstDialogueId, 
            numberOfDialoguesCompleted: 0, // This value needs to be dynamically set based on actual progress
            status: "inProgress"
          }]
        };
        fetch(`${serverUrl}/userProgress/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
          body: JSON.stringify(progressData)
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to save user progress');
          }
          return response.json();
        })
        .catch(error => console.error('Error updating user progress:', error));
      }
      navigate('/World', { state: { currentWorldOrder: currentWorldOrder, progressDialogue: null } });
    } else if (status === "continue") {
    navigate('/World', { state: { currentWorldOrder: currentWorldOrder, progressDialogue: progressDialogue } });}
  };  
  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="world-container"
    >
      <h1>Welcome to the World of {selectedWorldType}</h1>
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          description && (
            <motion.p
              className="overview"
              initial={{ x: '-100vw' }}
              animate={{ x: 0 }}
              transition={{ type: 'fade', duration: 1}}
            >
              Overview: {description}
            </motion.p>
          )
        )}
      </div>
      <div className='row'>
        <div className='col-4'>
          <motion.img
            src={FinnHappy}
            alt="Finn Happy"
            initial={{ scale: 0 }}
            animate={{ scale: [1, 1.05, 1] }}
            transition={{
              scale: { repeat: Infinity, duration: 4, ease: "easeInOut" }
            }}
          />
        </div>
        <div className='col-8'>
          <TextBubble>
            {'Excellent choice! Ready to dive deeper into ' + selectedWorldType + '?'}
          </TextBubble>
          <Flex className="button-container" justifyContent="space-around" alignItems="center">
            <Button colorScheme="teal" size="lg" onClick={() => redirectToWorld("begin")}>
              Begin Adventure
            </Button>
            {continueProgress && (
              <Button colorScheme="teal" size="lg" onClick={() => redirectToWorld("continue")}>
                Continue Adventure
              </Button>
            )}
          </Flex>
        </div>
      </div>
    </motion.div>
  );  
}

export default WorldStart;