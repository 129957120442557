import React, { useContext } from 'react';
import './ContainerBackground.css';
import { ThemeContext } from '../ThemeContext/ThemeContext';


const ContainerBackground = ({ children }) => {
    const { currentTheme } = useContext(ThemeContext);
    const style ={
        backgroundImage: currentTheme.gradient
    };

    return (
        <div className="container-background"style={style}>
            <div className="container-content">
                {children}
            </div>
        </div>
    );
};

export default ContainerBackground;
