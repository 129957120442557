import React, { useState } from 'react';
import exit1 from '../../Assets/Images/Exit-btn1.png';
import exit2 from '../../Assets/Images/Exit-btn2.png';
import './PanicButton.css'
import { ArrowDownIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const PanicButton = ({ isVisible = false }) => {
  const [imgSrc, setImgSrc] = useState(exit2); // Set default image as exit2
  const MotionBox = motion(Box);

  const handleClick = () => {
    window.open('https://www.youtube.com', '_blank', 'noopener,noreferrer'); // Open a new window for YouTube
    window.location.href = 'https://www.youtube.com'; // Alternative for opening a new window
    window.close(); // Close the current window
  };

  return (
    <div>
      {isVisible && (
        <MotionBox position="fixed" right="20px" bottom="150px"
                   width="100px" height="130px"
                   display="flex" alignItems="center" justifyContent="center"
                   initial={{ y: 0 }}
                   animate={{ y: ["0%", "10%", "0%"] }}
                   transition={{ repeat: Infinity, duration: 2, repeatDelay: 0 }}>
          <ArrowDownIcon w="full" h="full" color="#FF0000" />
        </MotionBox>
      )}
    <div className={isVisible ? 'panic-container' : 'hidden'}>
    <img 
      src={imgSrc} 
      alt="Panic Button" 
      onClick={handleClick} 
      onMouseEnter={() => setImgSrc(exit1)} // Change to exit1 on mouse enter
      onMouseLeave={() => setImgSrc(exit2)} // Revert to exit2 on mouse leave
      className='panicButton'
      width="100px"
      height="auto"
      title='Click me to exit the website!'
      style={{
        cursor: 'pointer',
        position: 'fixed', // Use fixed to make it stay in place on scroll
        right: '20px', // Distance from the right edge of the viewport
        bottom: '20px', // Distance from the bottom edge of the viewport
      }} 
    />
    </div>
    </div>
  );
};

export default PanicButton;