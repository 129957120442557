import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

const AnswersOverlay = ({ isOpen, onClose, answers, userAnswers }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl"> 
      <ModalOverlay />
      <ModalContent m={4} p={6}>
        <ModalHeader align="center">💡📝 Answer Key 📝💡</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant="simple" paddingX={5}>
            <Thead>
              <Tr>
                <Th fontSize="medium">Text Bubble</Th>
                <Th fontSize="medium">Correct Match(es)</Th>
                <Th fontSize="medium">Your Answer</Th>
              </Tr>
            </Thead>
            <Tbody>
              {answers.map((answer, index) => (
                <Tr key={index}>
                  <Td fontWeight="semibold">{answer.draggable}</Td>
                  <Td>{answer.containers.join(", ")}</Td>
                  <Td>{userAnswers[answer.draggable] ? userAnswers[answer.draggable]: "No attempt"}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default AnswersOverlay;