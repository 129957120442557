// component for displaying text on the page without using toasts or as a fallback for toasts

import React, { useEffect } from 'react';
import { Box, useEditable} from '@chakra-ui/react';
import { Fade, ScaleFade, Slide, SlideFade, Collapse } from '@chakra-ui/react'

function TextCard({ text, bgColor='blue.500', textColor='white', textSize='md', duration=null }) {
  const [show, setShow] = React.useState(true);

  useEffect(() => {
    if (duration) {
      const timeoutId = setTimeout(() => {
        setShow(false);
      }, duration);

      return () => clearTimeout(timeoutId);
    }
  }, [duration]);

  if (!text || !show) {
    return null;
  }

  switch (bgColor) {
    case 'blue':
      bgColor = 'blue.500';
      break;
    case 'green':
      bgColor = 'green.500';
      break;
    case 'red':
      bgColor = 'red.500';
      break;
    case 'yellow':
      bgColor = 'yellow.500';
      break;
    case 'purple':
      bgColor = 'purple.400';
      break;
    default:
      bgColor = bgColor;
  }
  switch(textColor){
    case 'white':
      textColor = 'white';
      break;
    case 'black':
      textColor = 'black';
      break;
    case 'blue':
      textColor = 'blue.500';
      break;
    case 'green':
      textColor = 'green.500';
      break;
    case 'red':
      textColor = 'red.500';
      break;

    default:
      textColor = textColor;
  }
  
  return (
    <Fade in={true}>
      <Box
        bg={bgColor}
        p={4}
        color={textColor}
        borderRadius="lg"
        boxShadow="lg"
        fontSize={textSize}
        mb={4}
      >
        {text}
      </Box>
    </Fade>
      
   
  );
}

export default TextCard;
