import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import './Map.css';
import MapImg from '../../Assets/Images/Map-lg.png';
import MapOverlay from '../../Assets/Images/map-overlay.png';

function Map({ onWorldClick }) {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [mapAreas, setMapAreas] = useState([]); // Holds the current set of coordinates for the map
  const [hoveredArea, setHoveredArea] = useState(null);
  const [focusedArea, setFocusedArea] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [worldsData, setWorldsData] = useState({});
  const imgRef = useRef();

  const originalWidth = 2560;
  const originalHeight = 2560;

  useEffect(() => {
    // Function to fetch data from the API
    const fetchWorldsData = async () => {
      try {
        const response = await fetch(`${serverUrl}/worlds`);
        const data = await response.json();
        
        // Create an object that maps world names to descriptions
        const worldDescriptions = data.reduce((acc, world) => {
          acc[world.creativeName] = world.description;
          return acc;
        }, {});

        // Set the state with the fetched data
        setWorldsData(worldDescriptions);
      } catch (error) {
        console.error('Failed to fetch world descriptions:', error);
      }
    };

    // Call the fetch function
    fetchWorldsData();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
   
  const mapAreas = [
    { coords: "141,1363 252,1251 116,1201 570,1118 566,1154 581,1285 251,1360", worldType: 'Introduction', label:1 },
    { coords: "79,1721 173,1764 91,1842 155,1842 427,1800 418,1673 376,1676", worldType: 'Key Terms', label:2},
    { coords: "306,2188 455,2255 329,2375 472,2376 858,2309 842,2106", worldType: 'Puberty Health/Gender exploration', label:3 },
    { coords: "1360,1906 1464,1966 1361,2048 1449,2053 1744,2029 1746,1888 1490,1905", worldType: 'Social transition', label:4},
    { coords: "1105,1557 1221,1608 1104,1718 1226,1717 1556,1655 1548,1565 1546,1490", worldType: 'Medical Transition', label:5 },
    { coords: "576,585 582,608 608,746 949,715 1048,689 911,617 1022,529 845,556", worldType: 'Legal Transition', label:6 },
    { coords: "1181,129 1304,179 1198,279 1285,278 1610,225 1600,111 1600,68 1330,112", worldType: 'Sex Education', label:7},
    { coords: "879,1030 985,1076 891,1164 982,1161 1255,1112 1242,979 1152,991", worldType: 'STBBI’s', label:8},
    { coords: "2083,597 2527,542 2417,628 2551,698 2444,729 2113,760", worldType: 'Mental Health', label:9},
    { coords: "1935,2260 2066,2338 1922,2446 1977,2455 2084,2455 2448,2423 2452,2230 2324,2234 2074,2257", worldType: 'Community Connections', label:10 }
  ];

  const normalizeCoords = (coords) => {
    return coords.split(' ').map(coord => {
      const [x, y] = coord.split(',').map(Number);
      const xPercent = (x / originalWidth) * 100;
      const yPercent = (y / originalHeight) * 100;
      return `${xPercent},${yPercent}`;
    }).join(' ');
  };

  setMapAreas(mapAreas.map(area => ({
    ...area,
    normalizedCoords: normalizeCoords(area.coords)
  })));
}, []);

  const handleAreaClick = (worldType) => {
    onWorldClick(worldType);
    navigate('/world-start', { state: { worldType } });
  };
  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };
  const handleKeyDown = (e, worldType) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleAreaClick(worldType);
    }
  };

  const calculateAbsoluteCoords = (normalizedCoords, imgWidth, imgHeight) => {
    return normalizedCoords.split(' ').map(coord => {
      const [xPercent, yPercent] = coord.split(',').map(Number);
      const x = (xPercent / 100) * imgWidth;
      const y = (yPercent / 100) * imgHeight;
      return `${x},${y}`;
    }).join(' ');
  };


  return (
    <div className="map-container">
      <div className="image-container" onMouseMove={handleMouseMove}>
        <img src={MapImg} alt="Interactive Map" useMap="#map" ref={imgRef}/>
        <img src={MapOverlay} alt="Map Overlay" className="map-overlay"/>
        <map name="map">
          {mapAreas.map((area, index) => (
            <area
              key={index}
              shape="polygon"
              coords={calculateAbsoluteCoords(area.normalizedCoords, imgRef.current?.width || originalWidth, imgRef.current?.height || originalHeight)}
              alt={`${area.worldType} Module - world ${area.label}`}
              aria-label={`${area.worldType} Module - world ${area.label}`}
              className={focusedArea === area.worldType ? 'focused-area' : ''}
              tabindex="0"
              onFocus={() => setFocusedArea(area.worldType)}
              onBlur={() => setFocusedArea(null)}
              onKeyDown={(e) => handleKeyDown(e, area.worldType)}
              onMouseEnter={(e) => setHoveredArea(area.worldType)}
              onMouseLeave={() => {
                setHoveredArea(null);
              }}
              onClick={() => handleAreaClick(area.worldType)}
            />
          ))}
        </map>
        {(hoveredArea)&& (
          <Box
            className="hover-text-box"
            position="fixed"
            left={`${mousePosition.x}px`}
            top={`${mousePosition.y}px`}
            transform="translate(10px, 10px)"
            bgColor="teal.500"
            color="white"
            p="2"
            borderRadius="md"
            boxShadow="md"
            zIndex="tooltip"
          >
            <Text fontSize="sm">
              {worldsData[hoveredArea] ? `Explore ${hoveredArea}: ${worldsData[hoveredArea]}` : `Loading details for ${hoveredArea}...`}
            </Text>
          </Box>
        )}
      </div>
    </div>
  );
}

export default Map;
