import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Link,
  VStack,
  Center,
  useToast,
  Heading,
  Text,
  Box,
  Spinner
} from '@chakra-ui/react';

function ForgotPassword() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  
  const [email, setEmail] = useState('');
  const toast = useToast();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      const response = await fetch(`${serverUrl}/auth/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setIsLoading(false);
        setIsEmailSent(true);
        
        toast({
          title: "Success",
          description: data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setIsLoading(false);
        throw new Error(data.message || 'Failed to send reset email');
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log(error);
    }
  };
  if (isLoading) {
    return (
      <Flex
        minHeight="50vh"
        width="full"
        align="center"
        justifyContent="center"
        p={4}
      >
        <Spinner />
      </Flex>
    );
  }
  if (isEmailSent) {
    return (
      <Flex
        minHeight="50vh"
        width="full"
        align="center"
        justifyContent="center"
        p={4}
      >
        <Box textAlign="center" p={10} borderWidth={1} borderRadius="lg">
          <Heading as="h2" size="lg">Check your inbox!</Heading>
          <Text fontSize="lg">
            Email sent successfully to your inbox. Please click on the link enclosed in the email we sent you to reset your password.
          </Text>
          <Link as={RouterLink} to="/login" mt={4} textAlign="center">Back to Login</Link>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex
      minHeight="50vh"
      width="full"
      align="center"
      justifyContent="center"
      p={4}
    >
      <VStack
        spacing={4}
        align="stretch"
        m={4}
        maxWidth="sm"
        bgColor="white"
        p={10}
        borderRadius={8}
      >
        <Center>
          <VStack>
          <Heading>Forgot your password?</Heading>
          <Text>Enter your email address and we will send you a link to reset your password.</Text>
          </VStack>
        </Center>
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel htmlFor="email">Email:</FormLabel>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button mt={4} colorScheme="purple" width="full" type="submit">
            Submit
          </Button>
        </form>
        <Link as={RouterLink} to="/signup" color="teal.800" textAlign="center">Sign up for an account</Link>
        <Link as={RouterLink} to="/login" color="teal.800" textAlign="center">Back to Login</Link>
      </VStack>
    </Flex>
  );
}

export default ForgotPassword;
