import React, { useState } from 'react';
import { Input, Checkbox, Radio, Button, VStack } from '@chakra-ui/react';
import MessengerSlimette from '../MessengerSlimette/MessengerSlimette';

const ModalContentBody = ({ inputType, selectionOptions, onInputSubmit }) => {
  const [userInput, setUserInput] = useState('');
  const [textInput, setTextInput] = useState('');
  const [checkboxSelections, setCheckboxSelections] = useState([]);
  const [radioSelection, setRadioSelection] = useState('');
  const [selectedNextDialogue, setSelectedNextDialogue] = useState('');
  const [validationVisible, setValidationVisible] = useState(false); // Manages visibility of ValidatorSlimette

  const handleCheckboxChange = (e, id) => {
    // If a checkbox is checked, reset the radio selection
    setRadioSelection('');
    setSelectedNextDialogue('');
    if (e.target.checked) {
      setCheckboxSelections(prev => [...prev, id]);
    } else {
      setCheckboxSelections(prev => prev.filter(selectionId => selectionId !== id));
    }
  };

  const handleRadioChange = (id) => {
    // If a radio button is selected, clear all checkbox selections
    setCheckboxSelections([]);
    setTextInput('');
    setRadioSelection(id);
    const selectedOption = selectionOptions.find(option => option.id === id);
    if (selectedOption) {
      setSelectedNextDialogue(selectedOption.nextDialogue);
    }
  };

  const handleTextInputChange = (e) => {
    const newValue = e.target.value;
    setTextInput(newValue);
    // If typing in "Other", deselect any selected radio button
    if (newValue) {
      setRadioSelection('');
      setSelectedNextDialogue('');
    }
  };

  const handleSubmit = () => {
    setValidationVisible(false);

    // Validation: Check if any of the fields have a value before submitting
    const isCheckboxSelected = checkboxSelections.length > 0;
    const isRadioSelected = radioSelection !== '';
    const isTextProvided = userInput.trim() !== '' || textInput.trim() !== '';
    if (isCheckboxSelected || isRadioSelected || isTextProvided) {
    let inputData = {
      text: inputType === 'text' ? userInput : textInput,
      checkboxes: checkboxSelections,
      radio: radioSelection,
      nextDialogue: selectedNextDialogue,
    };
    // Hide ValidatorSlimette if it was previously shown
    setValidationVisible(false);
    onInputSubmit(inputData);
    } else {
      setValidationVisible(true);

      // Optionally, hide the validator after some time
      setTimeout(() => {
        setValidationVisible(false);
      }, 5000);
      return;
    }
    };


  return (
    <VStack spacing={4}>
      {inputType === 'text' && (
        <Input
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Enter response..."
        />
      )}
      {selectionOptions.map(option => {
        switch (option.type) {
          case 'checkbox':
            return (
              <Checkbox
                key={option.id}
                isChecked={checkboxSelections.includes(option.id)}
                onChange={e => handleCheckboxChange(e, option.id)}
              >
                {option.label}
              </Checkbox>
            );
          case 'radio':
            return (
              <Radio
                key={option.id}
                name="selectionOption"
                value={option.id}
                isChecked={radioSelection === option.id}
                onChange={() => handleRadioChange(option.id)}
              >
                {option.label}
              </Radio>
            );
          case 'text':
            return (
              <Input
                key={option.id}
                value={textInput}
                onChange={handleTextInputChange}
                placeholder="Other"
              />
            );
          default:
            return null;
        }
      })}
      <Button colorScheme="blue" onClick={handleSubmit}>Submit</Button>
      <MessengerSlimette isVisible={validationVisible} message="Please fill out the required fields." slimetteType={"validator"} />
    </VStack>
  );
};

export default ModalContentBody;
