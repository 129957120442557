import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Link,
  IconButton,
  useToast,
  Box,
  Text,
  Heading,
  Divider
} from '@chakra-ui/react';
import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

function ResourcesModal({ isOpen, onClose, resources }) {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const toast = useToast();
  const navigate = useNavigate();
  // State to track added resources
  const [addedResources, setAddedResources] = useState({});

  const onAddResource = (resource) => {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    //console.log(resources)
      // Check if user is logged in (token exists)
  if (!token) {
    // Function to handle button click
    const handleLoginClick = () => {
      toast.closeAll(); // Close the toast
      navigate('/loginfs'); // Navigate to the login page
    };
    
    // Display a toast or popup to login
    toast({
      title: "Unauthorized",
      description: "You must be logged in to add resources. Please log in.",
      status: "warning",
      duration: 5000,
      isClosable: true,
      position: "top",
      render: () => (
        <Box color="white" p={3} bg="blue.500">
          <Text>You need to log in to add resources!</Text>
          <Button colorScheme="yellow" onClick={handleLoginClick}>
            Login Now
          </Button>
        </Box>
      )
    });
    return; // Stop further execution if not logged in
  }
  if (!addedResources[resource.id]) {
  setAddedResources(prev => ({ ...prev, [resource.id]: true }));
    // First, fetch the current resources
    fetch(`${serverUrl}/userResource`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch resources.');
      }
      return response.json();
    })
    .then(data => {
        // Check if resource ID already exists in the array
        if (data.resources.includes(resource.id)) {
          toast({
            title: "Resource Already Exists",
            description: "This resource is already in your customized list.",
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top"
          });
          return; // Stop further execution
        }
  
        // If not, append the new resource ID and send the update back to the server
        const updatedResources = [...data.resources, resource.id];

        fetch(`${serverUrl}/userResource`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ resources: updatedResources })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to update resources.');
            }
            return response.json();
        })
        .then(updatedData => {
          toast({
            title: "Resource Added",
            description: "The resource has been successfully added to your list.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top"
          });
        })
        .catch(error => {
          toast({
            title: "Error",
            description: `Failed to add the resource: ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top"
          });
        });
    })
    .catch(error => {
      console.error('Error fetching current resources:', error.message);
      toast({
        title: "Error",
        description: `Failed to fetch current resources: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    });
}};
  
  // Group resources by topic
  const groupedResources = resources.reduce((acc, resource) => {
    acc[resource.topic] = acc[resource.topic] || [];
    acc[resource.topic].push(resource);
    return acc;
  }, {});

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resource Information</ModalHeader>
        <ModalBody>
          {Object.entries(groupedResources).map(([topic, resources], index) => (
            <div key={topic}>
              <Heading as="h3" size="lg" mt={index !== 0 ? 8 : 2} mb={4} color="teal.600">
                {topic}
              </Heading>
              <Divider mb={4} />
              {resources.map(resource => (
                <div key={resource.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <div>
                    <p>{resource.label}</p>
                  </div>
                  {addedResources[resource.id] ? (
                    <IconButton
                      aria-label="Resource Added"
                      icon={<CheckIcon />}
                      colorScheme="green"
                      isDisabled={true}
                    />
                  ) : (
                    <IconButton
                      aria-label="Add resource"
                      icon={<AddIcon />}
                      onClick={() => onAddResource(resource)}
                      colorScheme="green"
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ResourcesModal;