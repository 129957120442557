import React, { useState, useEffect } from 'react';
import './Support.css'
import { Box, Text, Link, VStack, Heading, Container, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, background } from '@chakra-ui/react';

const SupportPage = () => {
  const [resources, setResources] = useState([]);
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch(`${serverUrl}/support.json`)
      .then(response => response.json())
      .then(data => setResources(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [serverUrl]);

  return (
    <Container maxW="container.md" pt={5}>
      {resources.map((section, index) => (
        <Box key={index} p={5} mb={4}>
          <Heading size="md" mb={2} color="blue.600">{section.title}</Heading>
          {section.description && <Text mb={4} >{section.description}</Text>}
          {section.resources?.map((resource, idx) => (
            <Box key={idx} p={5} shadow="md" borderRadius={10} mb={4} bg = "white">
              <Text mb={2}>{resource.description}</Text>
              {resource.links?.map((link, linkIdx) => (
                <Text key={linkIdx} fontSize="sm" mb={1}>
                  <Link href={link.link} isExternal color="teal.600">
                    {link.label || 'Click here to visit the website'}
                  </Link>
                </Text>
              ))}
            </Box>
          ))}
          <Accordion allowToggle>
          {section.provinces?.map((province, provinceIdx) => (
            <AccordionItem key={`province-${provinceIdx}`}>
              <AccordionButton bgColor='white' _hover={{bg:'gray.100'}} borderRadius={5}>
              <Box flex="1" textAlign="left" fontWeight="bold" color="blue.600">
              {province.province}
             </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} bg="white">
                {province.resources.map((resource, resIdx) => (
                  <VStack key={resIdx} align="start" spacing={0}>
                    <div className='province-item'>
                    <Text mb={1}>{resource.description}</Text>
                    {resource.links.map((link, linkIdx) => (
                      <Link className="link-text" mb={2} key={linkIdx} href={link.link} isExternal color="teal.500">
                        {link.label ? `Learn more about ${link.label}` : "Click here to visit the website"}
                      </Link>
                    ))}
                    </div>
                  </VStack>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
          </Accordion>
        </Box>
      ))}
    </Container>
  );
};

export default SupportPage;