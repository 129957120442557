import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text
} from '@chakra-ui/react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure you want to send this message?</ModalHeader>
        <ModalBody>
          <Text>For the purpose of this activity, we encourage you to write in more detail. </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline"  mr={4} onClick={onConfirm} px={10} py={6}>
            Yes, send message
          </Button>
          <Button colorScheme="blue" onClick={onClose} px={10} py={6}>No, I want to write more</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
