import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Switch,
    Button
  } from '@chakra-ui/react';
  
  const AccessibilityMenu = ({ fontSize, setFontSize }) => {
    return (
      <Menu>
        <MenuButton
          as={Button}
          className="accessibility-menu"
          borderRadius="full"
          size="lg"
          p={0}
          minW={12}
          h={12}
          iconSpacing={1}
        >
          A
        </MenuButton>
        <MenuList zIndex={10}>
          <MenuItem minH="40px">
            <span style={{ marginRight: 12 }}>Bigger Font</span>
            <Switch
              id="font-size-switch"
              isChecked={fontSize === 'large'}
              onChange={() => setFontSize(fontSize === 'small' ? 'large' : 'small')}
              colorScheme="blue"
              size="md"
            />
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };
  
  export default AccessibilityMenu;
  