import React, { useState } from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import TextCard from '../TextCard/TextCard';//havent incorporated this yet
import {
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Button,
  Link,
  VStack,
  Center,
  Box,
  useToast  
} from '@chakra-ui/react';


function Login() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const { updateAuthState } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      username: userName,
      password: password,
  };
  //try to login with the user data
  try {
    const response = await fetch(`${serverUrl}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();

    if (data.token) {

      localStorage.setItem('token',data.token)
      if (updateAuthState) updateAuthState(data.token);

      toast({
        title: 'Successfully Logged In',
        description: data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/');

    } else {
      toast({
        title: 'Login Failed! Please check User Name or Password',
        description: data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  } catch (error) {
    toast({
      title: 'Error',
      description: 'There was a problem with your registration',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    console.error('Registration error:', error);
  }
  };

  return (
    
    <Flex
      minHeight="50vh" 
      width="full" 
      align="center" 
      justifyContent="center" 
      p={4} 
    >
      <VStack
        spacing={4}
        align="stretch"
        m={4} 
        maxWidth="sm" 
        bgColor="white"
        p={10}
        borderRadius={8}
      >
        <Center>
          <h2>Log In</h2>
        </Center>
        <Box as="form" onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel htmlFor="userName">User Name:</FormLabel>
            <Input
              id="userName"
              type="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel htmlFor="password">Password:</FormLabel>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button mt={4} colorScheme="purple" width="full" type="submit">
            Log In
          </Button>
        </Box>
        <Link as={RouterLink} to="/forgot-password" color="teal.800" textAlign="center">I forgot my password</Link>
        <Link as={RouterLink} to="/signup" color="teal.800" textAlign="center">Sign up for an account</Link>
      </VStack>
    </Flex>
  );
}

export default Login;
