import React, { useState, useEffect } from 'react';
import { VStack, Box, SimpleGrid, Text, Button, useToast, Spinner, Center, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { FaSignInAlt, FaGlobe } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; 
import { useTheme } from '../ThemeContext/ThemeContext';
import { useAuth } from '../auth/AuthContext';

function Activity() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const { currentTheme } = useTheme();
  const bgColor = currentTheme.color1;
  const toast = useToast();
  const navigate = useNavigate();
  const [activities, setActivities] = useState([]);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem('token');

    const fetchActivities = async () => {
      try {
        const progressResponse = await fetch(`${serverUrl}/userProgress/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!progressResponse.ok) {
          throw new Error('Failed to fetch progress data');
        }

        const progressData = await progressResponse.json();
        const activityListResponse = await fetch(`${serverUrl}/activities/activityList.json`);
        if (!activityListResponse.ok) {
          throw new Error('Failed to fetch activity list');
        }

        const activityListData = await activityListResponse.json();
        const activityMap = new Map(activityListData.map(item => [item.activity, item.name]));
        const loadedActivities = progressData.activityProgresses.map(activity => ({
          id: activity.name,
          name: activityMap.get(activity.name),
          unlocked: activity.unlocked,
          path: `/activity/${activity.name.toLowerCase().replace(/\s/g, '')}`
        }));

        setActivities(loadedActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
        toast({
          title: 'Error',
          description: 'Failed to load activities.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }finally {
        setIsLoading(false); // Set loading to false regardless of the outcome
      }
    };

    fetchActivities();
  }, [user, toast]);

  const redoActivity = async (activityName, path) => {
    try {
      const response = await fetch(`${serverUrl}/activities/${activityName}/activity.json`);
      if (!response.ok) {
        throw new Error('Failed to fetch activity details');
      }
      const activityData = await response.json();
      navigate(path, { state: { activityData }, from: 'activity'});
    } catch (error) {
      console.error('Error fetching activity details:', error);
      toast({
        title: 'Error',
        description: `Failed to load details for ${activityName}.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!user) {
    return (
      <Center height="auto" width="auto" p={50}>
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="50%"
          width={{ base: "90%", md: "50%" }}
          borderRadius="lg"
          bgGradient="linear(to-t, blue.200, blue.100)"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <VStack spacing={5}>
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Direct access unavailable
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              To continue, please log in or explore the world to unlock activities.
            </AlertDescription>
            <Button leftIcon={<FaSignInAlt />} colorScheme="blue" onClick={() => navigate('/login')}>
              Log In
            </Button>
            <Button leftIcon={<FaGlobe />} colorScheme="green" onClick={() => navigate('/map')}>
              Navigate World
            </Button>
          </VStack>
        </Alert>
      </Center>
    );
  }

  if (isLoading) {
    return (
      <Center height="50vh">
        <Spinner size="xl" label="Loading activities..." />
      </Center>
    );
  }

  return (
    <Box p={50}>
      <SimpleGrid columns={{ sm: 2, md: 3, lg: 4 }} spacing={5}>
        {activities.map((activity) => (
          <Box key={activity.id} p={5} shadow="md" borderWidth="1px" textAlign="center"
               bg={activity.unlocked ? currentTheme.color6 : "gray.200"}
               borderRadius="md"
               sx={{
                 filter: activity.unlocked ? 'none' : 'grayscale(100%)'
               }}>
            <FontAwesomeIcon
              icon={activity.unlocked ? faLockOpen : faLock}
              size="3x"
              color={activity.unlocked ? "green" : "gray"}
            />
            <Text mt={2}>{activity.name}</Text>
            <Button mt={4}
            color="white"
            bg={activity.unlocked ? currentTheme.color4 : 'gray.600'}
            _hover={{
              bg: activity.unlocked ? currentTheme.color5 : 'gray.600',
              transform: activity.unlocked ? 'scale(1.05)' : 'none'
            }}
            borderRadius="lg"
            onClick={activity.unlocked ? () => redoActivity(activity.id, activity.path) : undefined}
            isDisabled={!activity.unlocked}>
              {activity.unlocked ? "Redo Activity" : "Locked"}
            </Button>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default Activity;
