import { useState, useCallback, useRef, useEffect } from 'react';

const useFetchDialogue = (serverUrl, worldOrder) => {
  const [worldId, setWorldId] = useState('');
  const [activityName, setActivityName] = useState('');
  const [dialogues, setDialogues] = useState([]);
  const [currentDialogue, setCurrentDialogue] = useState({});
  const [dialogue, setDialogue] = useState('');
  const [fullDialogueText, setFullDialogueText] = useState('');
  const [inputType, setInputType] = useState('none');
  const [selectionOptions, setSelectionOptions] = useState([]);
  const [specialInstruction, setSpecialInstruction] = useState('');
  const [isDialogueComplete, setIsDialogueComplete] = useState(false);
  const [nextDialogueId, setNextDialogueId] = useState('');
  const [nextDefaultId, setNextDefaultId] = useState('');
  const [staticWorldId, setStaticWorldId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const intervalId = useRef(null);
  const previousDialogueId = useRef(null);

  const updateFullDialogueText = (text) => {
    setFullDialogueText(text);
    setDialogueProgressively(text);
  };


  // Fetch all dialogues for the world
  const fetchAllDialogues = useCallback(async () => {
    setIsLoading(true);
    try {
      // Fetch the world details first to get the world ID
      const orderResponse = await fetch(`${serverUrl}/worlds/${worldOrder}`);
      const worldData = await orderResponse.json();
      setWorldId(worldData._id);

      // Fetch all dialogues for the world using the world ID
      const response = await fetch(`${serverUrl}/dialogues/allDialogues/${worldData._id}`);
      const data = await response.json();
      
      if (Array.isArray(data)) {
        setDialogues(data);
        setStaticWorldId(data.length > 0 ? data[0].world : '');
      } else {
        console.error('Unexpected data format:', data);
        setDialogues([]);
      }
    } catch (error) {
      console.error('Error fetching dialogues:', error);
      setDialogues([]);
    } finally {
      setIsLoading(false);
    }
  }, [serverUrl, worldOrder]);

  // Fetch a specific dialogue by ID from the pre-fetched dialogues
  const fetchDialogue = useCallback(
    (id) => {
      setIsLoading(true);
      const dialogueData = dialogues.find((d) => d._id === id);

      if (dialogueData) {
        // Check if the dialogue ID is different from the previous one
        if (previousDialogueId.current !== id) {
          setCurrentDialogue(dialogueData);
          setNextDefaultId(dialogueData.nextDialogueDefault);
        
        // Replace placeholder with user's name if present
        if (dialogueData.dialogue && dialogueData.dialogue.includes('@{name}')) {
          const userNameLocal = localStorage.getItem('userName') || 'Friend';
          const updatedDialogue = dialogueData.dialogue.replace('@{name}', userNameLocal);
          updateFullDialogueText(updatedDialogue);
        } else {
          updateFullDialogueText(dialogueData.dialogue);
        }

        setInputType(dialogueData.inputType || 'none');
        //setSelectionOptions
        if (dialogueData.inputType === 'mixed' && dialogueData.options) {
          const processedOptions = dialogueData.options.map(option => ({
            id: option._id,
            label: option.name,
            type: option.tags.includes('multiselect') ? 'checkbox' 
                  : option.tags.includes('text') ? 'text' 
                  : 'radio',
            nextDialogue: option.nextDialogue
          }));
          setSelectionOptions(processedOptions);
        } else if (dialogueData.inputType === 'selection' && dialogueData.options) {
          setSelectionOptions(dialogueData.options.map(option => ({
            id: option._id,
            label: option.name,
            type: 'radio', // Only one option can be selected
            nextDialogue: option.nextDialogue
          })));
        } else if (dialogueData.inputType === 'multiselect' && dialogueData.options) {
          const processedOptions = dialogueData.options.map(option => ({
            id: option._id,
            label: option.name,
            type: option.tags.includes('multiselect') ? 'checkbox' 
                  : '',
            nextDialogue: option.nextDialogue
          }));
          setSelectionOptions(processedOptions);
        } 
        else {
          setSelectionOptions([]);
        }
        setSpecialInstruction(dialogueData.specialInstruction || '');
        setActivityName(dialogueData.activityName || '');
        previousDialogueId.current = id;
      }} else {
        console.error(`Dialogue with id ${id} not found`);
      }
      setIsLoading(false);
    },
    [dialogues]
  );

   // Fetch the first dialogue for the world
   const fetchFirstDialogue = useCallback(() => {
    if (dialogues.length > 0) {
      // Find the first dialogue based on the absence of a previous dialogue reference
      const firstDialogue = dialogues.find((d) => !dialogues.some((other) => other.nextDialogueDefault === d._id));
      if (firstDialogue) {
        fetchDialogue(firstDialogue._id);
      } else {
        console.error('No first dialogue found');
      }
    }
  }, [dialogues, fetchDialogue]);

  // Display the dialogue progressively, character by character
  const setDialogueProgressively = (text) => {
    if (typeof text !== 'string') return;
     // Replace each period followed by a space or end of line with a period and a <br/> tag
    const formattedText = text.replace(/\.(\s|$)/g, '.<br/>');
    let displayText = '';
    const characters = formattedText.split('');
    let index = 0;
    setIsDialogueComplete(false);
    clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      if (index < characters.length) {
        displayText += characters[index++];
        setDialogue(displayText);
      } else {
        clearInterval(intervalId.current);
        setIsDialogueComplete(true);
      }
    }, 15);
  };

  return {
    dialogue,
    currentDialogue,
    activityName,
    fullDialogueText,
    inputType,
    selectionOptions,
    specialInstruction,
    isDialogueComplete,
    nextDefaultId,
    nextDialogueId,
    staticWorldId,
    intervalId,
    isLoading,
    setDialogue,
    setIsLoading,
    fetchDialogue,
    fetchAllDialogues,
    fetchFirstDialogue,
    setDialogueProgressively,
    setIsDialogueComplete
  };
};

export default useFetchDialogue;
