import React, { createContext, useContext, useState, useEffect } from 'react';

const themes = {
    trans: {
        gradient: 'linear-gradient(to bottom, #5BCEFA, #FFFFFF, #F5A9B8)',
        color1: '#5BCEFA',
        color2: '#F5A9B8',
        color3: '#0E103D',
        color4: '#DA667B',
        color5: '#cf4d6f',
        color6: '#f8d2da',
        color7: '#cd5f79',
        color8: '#bdebfd',
        color9: '#7C94FF'
    },
    nonbinary: {
        gradient:'linear-gradient(to bottom, #ffb30f, #FFFFFF, #9B59D0)',
        color1: '#ffb30f',
        color2: '#9B59D0',
        color3: '#D6BA73',
        color4: '#ac5b96',
        color5: '#9649cb',
        color6: '#f4d3eb',
        color7: '#a4538e',
        color8: '#f9ebcc',
        color9: '#FC8972'
    },
    genderqueer: {
        gradient: 'linear-gradient(to bottom, #B899DF, #FFFFFF, #6B8E3B)',
        color1: '#B899DF',
        color2: '#6B8E3B',
        color3: '#8BBF9F',
        color4: '#8cbb57',
        color5: '#679436',
        color6: '#c7f099',
        color7: '#587537',
        color8: '#dcccef',
        color9: '#B765FF'   
    }
};

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'trans');
    useEffect(() => {
        // Update local storage when theme changes
        localStorage.setItem('theme', theme);
    }, [theme]);
    
    const switchTheme = (themeName) => {
        setTheme(themeName);
    };
    
    return (
        <ThemeContext.Provider value={{ currentTheme: themes[theme], switchTheme}}>
            {children}
        </ThemeContext.Provider>
    );
};
export const useTheme = () => useContext(ThemeContext);
