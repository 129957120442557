import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext'; // Adjust the path as necessary

const LoginProtectedRoute = ({ children }) => {
    const { user } = useAuth(); // Accessing user from auth context
  
    // Check if user exists, implying they are logged in
    return user ? children : <Navigate to="/login" replace />;
  };
  
  export default LoginProtectedRoute;
  