//This hook is used to log user out and clear the local storage after 30 minutes of inactivity.
import { useEffect, useState } from 'react';
import {useAuth} from '../auth/AuthContext';

const useInactivityTimer = (timeoutDuration = 1800000) => {
  const { user, logout } = useAuth() || {};

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
        clearTimeout(timeoutId);
        setIsActive(true);
        timeoutId = setTimeout(() => {
          localStorage.clear(); // Clear local storage after 30min of inactivity
          setIsActive(false); // Update state to reflect inactivity
          logout(); // Call logout regardless of user being logged in
        }, timeoutDuration);
      };

    // Events that should reset the timer
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    // Add event listeners
    events.forEach(event => window.addEventListener(event, resetTimer));

    // Set the initial timer
    resetTimer();

    return () => {
      clearTimeout(timeoutId); // Clean up on unmount
      events.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, [timeoutDuration]);

  return isActive;
};

export default useInactivityTimer;
