import React from 'react';
import { Box, Image, Text, VStack, Button, HStack } from '@chakra-ui/react';

// Component to display a graphic based on provided activity data
const Graphic = ({ activityData, onCompletion, name, description }) => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const imgURL = `${serverUrl}${activityData.graphic}`;
  const finishActivity = () => {
        onCompletion();
    };

  return (
    <VStack spacing={2} align="center" padding = "3em">
      
    <HStack spacing="5em">
    <VStack>
    <Box backgroundColor="white" padding="2em" borderRadius="md" boxShadow="md" align="center">
      <Text fontSize="2xl" fontWeight="bold">{name}</Text>
      <Text fontSize="md" width="20em">{description}</Text>
        
      <Button
            colorScheme="blue"
            onClick={finishActivity}
          >
            Finish
      </Button>
    </Box>
    </VStack>
      
      <Box >
        <Image src={imgURL} alt={name} objectFit="cover" maxHeight="70vh" />
      </Box>
      </HStack>
      
      
      
    </VStack>
  );
};

export default Graphic;
