/*
This component uses the all dialogues endpoints to reduce the number of api calls
*/
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Spinner,
  Center,
  useToast
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import './World.css';
import ContainerBackground from '../ContainerBackground/ContainerBackground';
import { useTheme } from '../ThemeContext/ThemeContext';
import PanicButton from '../PanicButton/PanicButton';
import MessengerSlimette from '../MessengerSlimette/MessengerSlimette';
import ModalContentBody from '../WorldModal/WorldModal';
import ResourcesModal from './ResourcesModal/ResourcesModal';
import EndScreen from '../EndScreen/EndScreen';
import FinnNeutral from '../../Assets/Images/Characters/Finn/Finn_neutral_light.png';
import FinnHappy from '../../Assets/Images/Characters/Finn/Finn_happy_light.png';
import useFetchDialogue from '../../hooks/useFetchDialogue';
import useUserProfile from '../../hooks/useUserProfile';
import AccessibilityMenu from '../World/AccessibilityMenu';
import BackToMapButton from '../World/BackToMapButton';
import DialogueQuestionOverlay from '../World/DialogueQuestionOverlay';
import { parseYesResources, extractResources, parseSpecialResources } from '../../utils/dialogueUtils';
import useWorldData from '../../hooks/useWorldData';

const World = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { currentTheme } = useTheme();
  const location = useLocation();
  const { progressDialogue } = location.state || {};

  // Custom hooks to fetch data
  const {userProfileExists, setUserProfileExists } = useUserProfile(serverUrl, token);
  // Use global world data state
  const {currentWorldOrder, setCurrentWorldOrder, currentWorldId, setCurrentWorldId } = useWorldData(serverUrl);
  //const [dialogue, setDialogue] = useState('');
  const {
    currentDialogue,
    activityName,
    fullDialogueText,
    inputType,
    dialogue,
    selectionOptions,
    specialInstruction,
    isDialogueComplete,
    nextDefaultId,
    nextDialogueId,
    staticWorldId,
    intervalId,
    isLoading,
    setIsLoading,
    fetchDialogue,
    setDialogue,
    fetchAllDialogues,
    fetchFirstDialogue,
    setDialogueProgressively,
    setIsDialogueComplete,
  } = useFetchDialogue(serverUrl, currentWorldOrder);

  // State variables
  const [finnImage, setFinnImage] = useState(FinnNeutral);
  const MotionModalContent = motion(ModalContent);
  const [showEndModal, setShowEndModal] = useState(false);
  const [arrowPointer, setArrowPointer] = useState(false);
  const [graphicSrcs, setGraphicSrcs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
  const { isOpen: isSupportOpen, onOpen: onSupportOpen, onClose: onSupportClose } = useDisclosure();
  const { isOpen: isResourcesOpen, onOpen: onResourcesOpen, onClose: onResourcesClose } = useDisclosure();
  const toast = useToast();
  const cancelLoginRef = useRef();
  const cancelSupportRef = useRef();
  const [slimetteVisible, setSlimetteVisible] = useState(false);
  const [fontSize, setFontSize] = useState('small');
  const [resources, setResources] = useState([]);

  // Fetch all dialogues when the component mounts
  useEffect(() => {
    fetchAllDialogues(currentWorldOrder);
  }, [fetchAllDialogues]);

  // Open modal when dialogue is complete and input is required
  useEffect(() => {
    if (isDialogueComplete && inputType !== 'none') {
      onOpen();
    }
  }, [isDialogueComplete, inputType, onOpen]);

  // Fetch the initial dialogue based on progress or world order
  useEffect(() => {
    if (progressDialogue) {
      fetchDialogue(progressDialogue);
    } else {
      fetchFirstDialogue();
    }
  }, [fetchDialogue, progressDialogue, currentWorldOrder]);

  // Handle special instructions when they are set
  useEffect(() => {
    if (specialInstruction === '@highlight{panicButton}') {
      setArrowPointer(true);
    } else if (specialInstruction === '@redirect{login}') {
      if (userProfileExists) {
        return;
      } else if (isDialogueComplete) {
        onLoginOpen();
      }
    } else if (specialInstruction === '@highlight{supportTab}' && isDialogueComplete) {
      onSupportOpen();
    } else if (specialInstruction.startsWith('@graphic{/')) {
      const imagePaths = specialInstruction.slice(10, -1).split(', ');
      const imageUrls = imagePaths.map(path => `${serverUrl}${path.trim()}`);
      Promise.all(imageUrls.map(url =>
        fetch(url).then(response => response.blob()).then(blob => URL.createObjectURL(blob))
      ))
        .then(urls => setGraphicSrcs(urls))
        .catch(error => console.error('Error fetching images:', error));
    }
  }, [specialInstruction, userProfileExists, isDialogueComplete, onLoginOpen, onSupportOpen, serverUrl]);

  // Handle user input submission
  const handleUserInputSubmit = async (inputData) => {
    const yesOption = selectionOptions.find(option => option.label === "Yes" && option.id === inputData.radio) || '';
    if (yesOption) {
      setFinnImage(FinnHappy); // Change Finn's mood to happy
    } else {
      setFinnImage(FinnNeutral);
    }

    // Handle resource fetching based on special instructions
    if (yesOption && specialInstruction.startsWith('@resources{@Yes{')) {
      const resourceIds = parseYesResources(specialInstruction);
      fetchResources(resourceIds);
    }

    if (specialInstruction.startsWith('@resources{@') && !specialInstruction.startsWith('@resources{@Yes{')) {
      const selectedResources = inputData.checkboxes.map(id => {
        const option = selectionOptions.find(option => option.id === id);
        return option ? option.label : null; // Return the option name if found, null otherwise
      }).filter(name => name !== null); // Filter out any nulls (in case an ID wasn't found)

      if (selectedResources.length > 0) {
        const resourceMap = extractResources(specialInstruction);
        const resourceIds = parseSpecialResources(resourceMap, selectedResources);

        fetchResources(resourceIds);
      }
    }

    const profileRequiresUpdate = currentDialogue.updateUserProfile;
    const userProfileField = currentDialogue.userProfileField;

    // Update user profile based on dialogue requirements
    const selectedPronouns = inputData.checkboxes.map(id => {
      const option = selectionOptions.find(option => option.id === id);
      return option ? option.label : null;
    }).filter(name => name !== null);

    const selectedGender = inputData.checkboxes.map(id => {
      const option = selectionOptions.find(option => option.id === id);
      return option ? option.label : null;
    }).filter(label => label !== null);

    // Prepare the data for updating the user profile based on the field to be updated
    let updateData = {};
    if (userProfileField === 'name') {
      updateData.name = inputData.text;
      // Storing inputData.text in localStorage as userName
      localStorage.setItem('userName', inputData.text);
    } else if (userProfileField === 'gender') {
      // Join the selected pronouns into a single string
      updateData.gender = selectedGender.join(', ');
    } else if (userProfileField === 'pronoun') {
      // Join the selected pronouns into a single string
      updateData.pronoun = selectedPronouns.join(', ');
    }

    if (profileRequiresUpdate && Object.keys(updateData).length > 0) {
      if (userProfileExists) {
        try {
          const method = 'PATCH';
          const updateUserProfileResponse = await fetch(`${serverUrl}/userProfiles`, {
            method: method,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updateData),
          });

          if (!updateUserProfileResponse.ok) {
            const errorData = await updateUserProfileResponse.json();
            console.error('Error updating user profile:', errorData);
            throw new Error(errorData.message || 'Failed to update user profile');
          }
          // Update local state after user profile is created
          if (!userProfileExists) {
            setUserProfileExists(true);
          }
        } catch (error) {
          console.error('Error updating user profile:', error);
        }
      } else {
        const existingGuestProfile = JSON.parse(localStorage.getItem('guestProfile')) || {};
        const updatedGuestProfile = {
          ...existingGuestProfile,
          [userProfileField]: inputData.text || selectedPronouns.join(', ') || '',
        };
        localStorage.setItem('guestProfile', JSON.stringify(updatedGuestProfile));
      }
    }

    if (inputData.nextDialogue) {
      fetchDialogue(inputData.nextDialogue);
    }
    onClose();
    setSlimetteVisible(true);
  };

  // Fetch activity data
  const fetchActivity = async (activityName) => {
    setIsLoading(true);

    try {
      const response = await fetch(`${serverUrl}/activities/${activityName}/activity.json`);
      if (!response.ok) {
        throw new Error('Failed to fetch activity data');
      }

      const activityData = await response.json();
      navigate(`/activity/${activityName}`, { state: { activityData, nextDialogueId, from: 'World' } });
    } catch (error) {
      console.error('Error fetching activity:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle the "Next" button click
  const handleNextClick = () => {
    if (!isDialogueComplete) {
      clearInterval(intervalId.current);
      // Process fullDialogueText to add line breaks
      const formattedDialogue = fullDialogueText.replace(/\.(\s|$)/g, '.<br/>');
      setDialogue(formattedDialogue); // Set the formatted dialogue with <br/> tags
      setIsDialogueComplete(true);
    } else if (activityName !== '') {
      saveProgress(nextDefaultId);
      fetchActivity(activityName);
      setArrowPointer(false);
      setGraphicSrcs([]);
    } else if (nextDialogueId) {
      saveProgress(nextDialogueId);
      fetchDialogue(nextDialogueId);
      setArrowPointer(false);
      setGraphicSrcs([]);
    } else if (nextDefaultId) {
      saveProgress(nextDefaultId);
      fetchDialogue(nextDefaultId);
      setArrowPointer(false);
      setGraphicSrcs([]);
    } else if (!nextDefaultId && !nextDialogueId) {
      worldComplete();
      setShowEndModal(true);
      setArrowPointer(false);
      setGraphicSrcs([]);
    }

    setSlimetteVisible(false);
    setFinnImage(FinnNeutral);
  };

  // Save user progress
  const saveProgress = async (dialogueId) => {
    // Fetch existing progress to preserve progress of other worlds
    const response = await fetch(`${serverUrl}/userProgress/`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const userData = await response.json();
  
    // Copy the existing worldProgresses array
    let updatedWorldProgresses = [...userData.worldProgresses];
  
    // Find the world that matches the current staticWorldId and update its progress
    const worldIndex = currentWorldOrder;
  
    if (worldIndex !== -1 && updatedWorldProgresses[worldIndex]) {
      updatedWorldProgresses[worldIndex] = {
        ...updatedWorldProgresses[worldIndex],
        nextDialogue: dialogueId,
        numberOfDialoguesCompleted: updatedWorldProgresses[worldIndex].numberOfDialoguesCompleted + 1, // Increment dialogues completed
        status: dialogueId ? 'inProgress' : 'completed'
      };
    } else {
      // If world progress doesn't exist, create a new entry
      updatedWorldProgresses.push({
        world: staticWorldId,
        nextDialogue: dialogueId,
        numberOfDialoguesCompleted: 1, // Starting with 1 completed dialogue
        status: dialogueId ? 'inProgress' : 'completed'
      });
    }
  
    const progressData = {
      lastWorld: staticWorldId,
      lastDialogue: dialogueId,
      worldProgresses: updatedWorldProgresses, // Update all world progresses, not just the current one
      status: dialogueId ? 'inProgress' : 'completed'
    };
  
    // Send the updated progress data to the server
    if (userProfileExists) {
      try {
        const patchResponse = await fetch(`${serverUrl}/userProgress/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(progressData)
        });
  
        if (!patchResponse.ok) {
          throw new Error(`Failed to save progress: ${patchResponse.status}`);
        }
      } catch (error) {
        console.error('Error saving progress:', error);
      }
    } else {
      // Save progress locally for non-logged-in users
      localStorage.setItem('lastWorld', progressData.lastWorld);
      localStorage.setItem('lastDialogue', progressData.lastDialogue);
    }
  };

  // Handle world completion
  const worldComplete = async () => {
    try {
      const progressData = {
        worldProgresses: [
          {
            world: staticWorldId,
            nextDialogue: null,
            numberOfDialoguesCompleted: 0,
            status: 'completed'
          }
        ],
        status: 'completed'
      };

      const response = await fetch(`${serverUrl}/userProgress/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(progressData)
      });

      if (!response.ok) {
        throw new Error(`Failed to save progress: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  // Fetch resources
  const fetchResources = (resourceIds) => {
    fetch(`${serverUrl}/resourceList.json`)
      .then(response => response.json())
      .then(data => {
        const filteredResources = data.filter(resource => resourceIds.includes(resource.id));
        setResources(filteredResources);
        onResourcesOpen();
      })
      .catch(error => console.error('Error fetching resources:', error));
  };

  return (
    <ContainerBackground theme={currentTheme}>
      <div className="world2-overlay">
        <div className="world2-container">
          <BackToMapButton />
          <AccessibilityMenu fontSize={fontSize} setFontSize={setFontSize} />
          <MessengerSlimette isVisible={slimetteVisible} message="Thank you for answering! Click 'Next' button to proceed" slimetteType={"validator"}/>
          <img src={finnImage} alt="Finn" className="finn-character finn-slide-in" />
          <div className='finn-speech-bubble' style={{ fontSize: fontSize === 'small' ? '18px' : '24px' }}>
          {isLoading ? (
            <Center><Spinner /></Center>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: dialogue }} />
          )}
            <div className="next-button-container">
              {!isLoading && (
                <Button onClick={handleNextClick}>Next</Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={() => {}} isClosable={false} closeOnEsc={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <MotionModalContent
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.25 }}
        >
          <ModalHeader>Enter or Select Response</ModalHeader>
          <ModalBody>
            <ModalContentBody
              inputType={inputType}
              selectionOptions={selectionOptions}
              onInputSubmit={handleUserInputSubmit}
            />
          </ModalBody>
          <ModalFooter />
        </MotionModalContent>
      </Modal>
      <DialogueQuestionOverlay
        isOpen={isLoginOpen}
        onClose={onLoginClose}
        leastDestructiveRef={cancelLoginRef}
        title="Login Required"
        description="Do you want to log in now?"
        redirectPath='/loginfs'
      />
      <DialogueQuestionOverlay
        isOpen={isSupportOpen}
        onClose={onSupportClose}
        leastDestructiveRef={cancelSupportRef}
        title="Resources for you"
        description="Do you want to access the Support resource now?"
        redirectPath='/support'
      />
      <div className="graphic-container">
        {graphicSrcs.map((src, index) => (
          <img key={index} src={src} alt={`Special Graphic ${index + 1}`} className="graphic" />
        ))}
      </div>
      <ResourcesModal isOpen={isResourcesOpen} onClose={onResourcesClose} resources={resources} />
      <EndScreen isVisible={showEndModal} />
      <PanicButton isVisible={arrowPointer} />
    </ContainerBackground>
  );
};

export default World;
