// loading spinner
import { Box, Spinner, Center, Text, keyframes, VStack  } from '@chakra-ui/react'
import React from 'react'

function RainbowSpinner () {
    //this spinner component will return a rainbow color spinner
    return (
        <Center height="50vh">
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            >
                <VStack>
                <Spinner
                    thickness="6px"
                    speed="1s" 
                    emptyColor="gray.200"
                    color="purple.500"
                    size="xl"   
                />
                <Text fontSize="lg" mt="4">Loading the page...</Text>
                </VStack>
            </Box>
        </Center>
    );
};

export default RainbowSpinner;
