import React from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import gqIcon from '../../Assets/gq-icon.svg';
import nbIcon from '../../Assets/nb-icon.svg';
import transIcon from '../../Assets/trans-icon.svg';
import { useTheme } from '../ThemeContext/ThemeContext';
import '../Box/Box.css';

const ThemeMenuButton = () => {
  const { currentTheme, switchTheme } = useTheme();

  return (
    <div>
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        backgroundColor={currentTheme.color1}
        className='themeDropDownMenu'
        _hover={{
          bg: 'white',
          color: 'black',
          boxShadow: `inset 0 0 0 5px ${currentTheme.color5}`
        }}
      >
        <FontAwesomeIcon icon={faPalette} className='themeIcon' />
        Change Theme
      </MenuButton>

      <MenuList>
        <MenuItem icon={<img src={transIcon} alt="Trans Flag" />} onClick={() => switchTheme('trans')}>
          <img src={transIcon} alt="Trans Flag" style={{ marginRight: "8px" }} />
          Trans Flag Theme
        </MenuItem>
        <MenuItem icon={<img src={nbIcon} alt="Non-binary Flag" />} onClick={() => switchTheme('nonbinary')}>
          <img src={nbIcon} alt="Non-binary Flag" style={{ marginRight: "8px" }} />
          Non-binary Flag Theme
        </MenuItem>
        <MenuItem icon={<img src={gqIcon} alt="Genderqueer Flag" />} onClick={() => switchTheme('genderqueer')}>
          <img src={gqIcon} alt="Genderqueer Flag" style={{ marginRight: "8px" }} />
          Genderqueer Flag Theme
        </MenuItem>
      </MenuList>
    </Menu>
    </div>
  );
};

export default ThemeMenuButton;
