import React, { useEffect, useState, useCallback } from 'react';
import './Home.css';
import { SimpleGrid, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import homeGraphic from '../../Assets/Images/home.png';
import { useTheme } from '../ThemeContext/ThemeContext';

function Home() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  let navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [worldOrder, setWorldOrder] = useState('');
  const [worldId, setWorldId] = useState('');
  const [progressDialogue, setProgressDialogue] = useState('');
  const { currentTheme } = useTheme();

  //Checking the if progress exists
  const progressChecker = useCallback(() => {
    fetch(`${serverUrl}/userProgress/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          setWorldId(data.lastWorld)
          if(data.lastDialogue !== null){
            setProgressDialogue(data.lastDialogue);
          }
        }
      })
      .catch(error => console.error('Error fetching dialogue ID:', error));
  }, [token]);

    //Checking the if progress exists
    const fetchWorldOrder = useCallback(() => {
      fetch(`${serverUrl}/worlds`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          const worldOrder = data.find(world => world._id === worldId);
          if (worldOrder) {
            setWorldOrder(worldOrder.order)
            // Process your world order here
          } else {
            console.error('World not found');
          }
        })
        .catch(error => console.error('Error fetching world order:', error));
    }, [worldId, token]);

    useEffect(() => {
      if (token) {
        setIsLoggedIn(true);
        progressChecker();
        fetchWorldOrder();
      } else {
        setIsLoggedIn(false);
      }
    },[token, progressChecker, fetchWorldOrder] );

  const handleStartWithoutAcc = () => {
    navigate('/map');
  }

  const handleLogin = () => {
    navigate('/loginfs');
  }

  const handleMap = () => {
    navigate('/map');
  }

  const handleContinueWorld = () => {
    if(progressDialogue !== null){
      navigate('/world', { state: { worldOrder: worldOrder, progressDialogue: progressDialogue } });
    }
    else {
      navigate('/map');
    }
  }

  return (
    <div className='home-container'>
      <div className="home-wrapper">
      <div className="home-layout">
      
      <Text
        className="home-title"
        bgGradient={[
          'linear(to-tr, teal.800, green.800)',
          'linear(to-t, pink.800, blue.800)',
          'linear(to-b, teal.800, purple.700)',
        ]}
        bgClip='text'
        fontWeight='extrabold'
        
      >
        Gender-Affirming, Life-Affirming: 
      </Text>
      <Text
        className="home-subtitle"
        bgGradient={[
          'linear(to-tr, green.800, red.800)',
          'linear(to-t, pink.800, teal.800)',
          'linear(to-b, purple.900, green.800)',
        ]}
        bgClip='text'
        fontWeight='extrabold'
        
      >
        An online puberty and sexual health resource for gender-independent, nonbinary, transgender, and intersex youth.

      </Text>
      <img src={homeGraphic} alt="Home graphic" className="home-graphic"/>

      {!isLoggedIn && (
        <SimpleGrid columns={[1, null, 2]} spacing="60px">
          <Button 
                key="start" 
                onClick={handleStartWithoutAcc} 
                size="lg"
                backgroundColor={currentTheme.color1}
                _hover={{
                  bg: currentTheme.color9, 
                  color: 'black', 
              }}
                variant="solid"
              >
                Explore the map
              </Button>
              <Button 
                key="login" 
                onClick={handleLogin} 
                size="lg"
                backgroundColor={currentTheme.color1}
                _hover={{
                  bg: currentTheme.color9, 
                  color: 'black', 
              }}
                variant="solid"
              >
                Login or create a new account
              </Button>
        </SimpleGrid>
      )}
      {isLoggedIn && (
        <SimpleGrid columns={[1, null, 2]} spacing="60px">
          <Button 
                key="start" 
                onClick={handleMap} 
                size="lg"
                backgroundColor={currentTheme.color1}
                _hover={{
                  bg: currentTheme.color9, 
                  color: 'black', 
              }}
                variant="solid"
              >
                Explore Map
              </Button>
              <Button 
                key="continue" 
                onClick={handleContinueWorld} 
                size="lg"
                backgroundColor={currentTheme.color1}
                _hover={{
                  bg: currentTheme.color9, 
                  color: 'black', 
              }}
                variant="solid">

          Continue your adventure
              </Button>
        </SimpleGrid>
      )}
      </div>
      </div>
    </div>
  )
}

export default Home;