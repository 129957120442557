import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const BackToMapButton = () => {
  const navigate = useNavigate();

  return (
    <Button className="back-to-map-btn" onClick={() => navigate('/map')}>Back to Map</Button>
  );
};

export default BackToMapButton;
